//google captcha key 
export const GOOGLE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

// prod url frontend;
export const API_PROD_URL =process.env.REACT_APP_API_PROD_URL;


//dev url backend;
// export const API_BASE_URL = process.env.REACT_APP_DEV_API_BASE_URL;

//prod url backend
export const API_BASE_URL = process.env.REACT_APP_PROD_API_BASE_URL;

//country code
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

//dev url frontend
export const API_DEV_URL_FRONTEND = process.env.REACT_APP_API_DEV_URL;

//prod url coke studio 
export const COKE_BANGLA_PROD_URL =process.env.REACT_APP_COKE_BANGLA_PROD_URL;

//dev url prod bangla 
export const COKE_BANGLA_DEV_URL =process.env.REACT_APP_COKE_BANGLA_DEV_URL;

// FOR TESTING local url 
export const REACT_LOCAL_BASE_URL =process.env.REACT_APP_LOCAL_BASE_URL;