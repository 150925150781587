import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import ProfileMenu from "./profile/ProfileMenu";
import MyWallet from "./my-wallet/MyWallet";
import UniqueCode from "./UniqueCode";
import LanguageSwitcher from "./LanguageSwicher";
import CommonModel from "../model/CommonModel";
import { Button } from "react-bootstrap";
import useAuth from "../../utils/hooks/use-auth";
import Cookies from "js-cookie";
import useTranslation from "../../utils/hooks/use-translation";
import { fetchWalletByUserId } from "../../redux/slice/walletSlice";
import AppModal from "../model/AppModal";
import WinTicketModal from "../model/WinTicketModal";
import ClaimTicketFormModal from "../model/ClaimTicketFormModal";
import { saveTicketWinner } from "../../service";
import UniqueCodeForm from "../form/UniqueCodeForm";

import NotEligibleModal from "../model/NotEligibleModal";
import { ELIGABLE_COUNTRY } from "../../config/constant";
import { ClickButtonCDP } from "../../utils/cdsHelper";
import AlreadyWonTheTicket from "../model/AlreadyWonTheTicket";
import CampaignEnded from "../model/CampaignEnded";
import { isFoodPandaEnded } from "../../utils/helper";

const Header = () => {
  const { userDetails, isLoggedIn } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showWallet, setShowMyWallet] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [showWinTicketModal, setShowWinTicketModal] = useState(false);
  const [showClaimFormModal, setShowClaimFormModal] = useState(false);
  const [showUniqueCodeModal, setUniqueCodeModal] = useState(false);
  const [victoryCoin, setVictoryCoin] = useState({ title: "" });
  const { currentLanguage, changeLanguage } = useTranslation();
  const { ipDetails } = useSelector((state) => state.common);
  const [eligiblityModal, setEligiblityModal] = useState(false);
  const [alreadyWonTheTicket, setAlreadyWonTheTicket] = useState(false);
  const [campaignEnded, setCampaignEnded] = useState(false);

  const isCountryNotEligible =
    ipDetails && !ELIGABLE_COUNTRY.includes(ipDetails?.country);
  const walletDetails = useSelector((state) => state.wallet.walletDetails);
  const isFoodPandaTicketWin =
    walletDetails &&
    walletDetails?.walletData?.FoodPandaTicket === 1 &&
    walletDetails?.walletData?.TicketClaimed != 1 && !isFoodPandaEnded()

  console.log(isFoodPandaEnded(), "isFoodPandaEnded");
  const ticketWinnerModal = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData?.CoinDetail
  );

  const user_details = useSelector((state) => state?.auth?.userDetails);
  const enableTickeWinnerModal = ticketWinnerModal?.CTGoldCoin >= 5;

  useEffect(() => {
    if (user_details) {
      getWalletDetails();
    }
  }, [user_details]);

  useEffect(() => {
    if (user_details) {
      getWalletDetails();
    }
  }, [showWallet, enableTickeWinnerModal]);

  useEffect(() => {
    if (isFoodPandaTicketWin) {
      setUniqueCodeModal(false);
      setShowClaimFormModal(true);
    }
  }, [isFoodPandaTicketWin]);

  useEffect(() => {
    if (
      (location.search == "?qr=true" && userDetails) ||
      (location.search == "?Ref=foodpanda" &&
        userDetails &&
        !isFoodPandaTicketWin)
    ) {
      openPopup();
    } else if (
      (location.search == "?qr=true" && !userDetails) ||
      (location.search == "?Ref=foodpanda" && !userDetails)
    ) {
      handleShowLoginModal();
    }
  }, [location.search]);
  const openPopup = () => {
    setUniqueCodeModal(true);
  };

  const handleShowLoginModal = () => {
    setActiveStep(2);
    setShowLoginModal(true);
  };
  useEffect(() => {
    if (isCountryNotEligible) {
      setActiveStep(1);
      setEligiblityModal(true);
      setShowLoginModal(false);
    }
  }, [activeStep]);

  const handleClose = () => {
    setShowLoginModal(false);
    setShowMyWallet(false);
    setShowWinTicketModal(false);
  };

  /**
   * @method getWalletDetails
   * @description get wallet details api calling
   */
  const getWalletDetails = async () => {
    dispatch(fetchWalletByUserId());
    return;
  };

  /**
   * @description save the winner into the database
   */
  const saveTicketWinnerApi = async () => {
    let requestData = {
      UserId: user_details && user_details.Id,
    };
    try {
      const response = await saveTicketWinner(requestData);
      if (response.status === 200) {
        setUniqueCodeModal(false);
        setVictoryCoin("");
        setShowWinTicketModal(true);
      }
    } catch (error) {
      console.error("Error updating profile name:", error);
    }
  };

  //coderedeem manage the silver gold coin popups
  const codeRedeemModel = (coin_type) => {
    console.log(coin_type, "coin_type");
    if (coin_type && coin_type?.CTGoldCoin === 1) {
      setUniqueCodeModal(false);
      setVictoryCoin({ title: "Silver" });
    } else {
      setUniqueCodeModal(false);
      setVictoryCoin("");
    }
  };
  // Handler for language change
  const handleLanguageChange = (event) => {
    const newLanguage = event?.target?.value ? event.target.value : event;
    changeLanguage(newLanguage);
    // Store the selected language in a cookie
    Cookies.set("language", newLanguage, { expires: 7 });
    const cdpData = {
      language_code: newLanguage,
    };
    if (location.search == "?qr=true" && showUniqueCodeModal) {
      ClickButtonCDP("Change_Language", cdpData, "Click");
    } else {
      ClickButtonCDP("Change_Language", cdpData, "Select");
    }
  };

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Redirect to the home page
    navigate("/");
  };

  return (
    <>
      {/* Header */}
      <header className="header">
        {/* Header Top */}
        <div className="header-top d-md-none">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center py-1">
              {/* <div className="col-3 col-sm-2 ">
                <LanguageSwitcher
                  handleLanguageChange={handleLanguageChange}
                  currentLanguage={currentLanguage}
                />
              </div> */}
              <div className="col d-flex justify-content-center">
                <UniqueCode
                  setShowLoginModal={handleShowLoginModal}
                  getWalletDetails={getWalletDetails}
                  walletDetails={walletDetails}
                  setVictoryCoin={setVictoryCoin}
                  victoryCoin={victoryCoin}
                  codeRedeemModel={codeRedeemModel}
                  saveTicketWinnerApi={saveTicketWinnerApi}
                  setAlreadyWonTheTicket={setAlreadyWonTheTicket}
                  setCampaignEnded={setCampaignEnded}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Header Navigation */}
        <div className="container-fluid">
          <div className="header-nav">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-flex align-items-center justify-content-lg-center">
                  <Sidebar
                    t={t}
                    setShowLoginModal={handleShowLoginModal}
                    setShowMyWallet={setShowMyWallet}
                    handleLanguageChange={handleLanguageChange}
                    currentLanguage={currentLanguage}
                  />
                  <div className="d-none d-md-block">
                    {/* UniqueCode */}
                    <UniqueCode
                      setShowLoginModal={handleShowLoginModal}
                      getWalletDetails={getWalletDetails}
                      walletDetails={walletDetails}
                      setVictoryCoin={setVictoryCoin}
                      victoryCoin={victoryCoin}
                      codeRedeemModel={codeRedeemModel}
                      saveTicketWinnerApi={saveTicketWinnerApi}
                      setAlreadyWonTheTicket={setAlreadyWonTheTicket}
                      setCampaignEnded={setCampaignEnded}
                    />
                  </div>
                </div>
              </div>
              <div className="col d-flex justify-content-center">
                <div>
                  {/* Logo */}
                  <Link to="/" onClick={handleClick}>
                    <img
                      src="/assets/images/logo/logo.svg"
                      alt="Coca-Cola"
                      className="header-logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col d-flex  justify-content-end align-items-center pl-0">
                {userDetails && userDetails.Id && (
                  <div className="d-flex align-items-center">
                    <Button
                      className="p-0 tu-wallet-link mr-md-6 mr-3"
                      variant="link"
                      onClick={() => {
                        setShowMyWallet(true);
                      }}
                    >{`${t.wallet}`}</Button>

                    <MyWallet
                      setShowLoginModal={setShowLoginModal}
                      show={showWallet}
                      handleClose={handleClose}
                      getWalletDetails={getWalletDetails}
                      walletDetails={walletDetails}
                      isFoodPandaTicketWin={isFoodPandaTicketWin}
                      setCampaignEnded={setCampaignEnded}
                    />
                    <ProfileMenu
                      getWalletDetails={getWalletDetails}
                      walletDetails={walletDetails}
                      setShowClaimFormModal={setShowClaimFormModal}
                      setCampaignEnded={setCampaignEnded}
                    />
                  </div>
                )}
                {!userDetails && (
                  <div className="d-flex align-items-center">
                    <Button
                      className="p-0 tu-wallet-link bdloginbtn"
                      variant="link"
                      onClick={() => handleShowLoginModal()}
                    >{`${t.login}`}</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <div style={{ position: "relative" }}>
        {/* CommonModel */}
        {!userDetails && showLoginModal && (
          <CommonModel
            title={t.worldCup2023TicketKey}
            header={t.find_code}
            handleShowLoginModal={handleShowLoginModal}
            showLoginModal={showLoginModal}
            handleClose={handleClose}
            active={activeStep}
          />
        )}
      </div>
      {showWinTicketModal && (
        <AppModal
          onHide={handleClose}
          maxWidth="md"
          bodyClassName="pt-md-4 pt-6 px-0 pb-8"
        >
          <WinTicketModal
            handleClose={handleClose}
            closePopup={handleClose}
            setTicketModel={setShowClaimFormModal}
            setCampaignEnded={setCampaignEnded}
          />
        </AppModal>
      )}
      {/* claim form modal  */}
      {showClaimFormModal && (
        <ClaimTicketFormModal
          show={showClaimFormModal}
          handleClose={() => setShowClaimFormModal(false)}
          walletDetails={walletDetails}
          isFoodPandaTicketWin={isFoodPandaTicketWin}
          t={t}
          // source={ticketModel}
        />
      )}

      {/* enter unique coed if the user comes while scanning the QR code  */}
      {showUniqueCodeModal && (
        <UniqueCodeForm
          victoryCoin={victoryCoin}
          setVictoryCoin={setVictoryCoin}
          show={showUniqueCodeModal}
          t={t}
          handleShowLoginModal={handleShowLoginModal}
          getWalletDetails={getWalletDetails}
          onHide={() => setUniqueCodeModal(false)}
          codeRedeemModel={codeRedeemModel}
          handleLanguageChange={handleLanguageChange}
          currentLanguage={currentLanguage}
          saveTicketWinnerApi={saveTicketWinnerApi}
          setShowWinTicketModal={setShowWinTicketModal}
          setUniqueCodeModal={setUniqueCodeModal}
          walletDetails={walletDetails}
          setAlreadyWonTheTicket={setAlreadyWonTheTicket}
          setCampaignEnded={setCampaignEnded}
        />
      )}

      {eligiblityModal && (
        <AppModal
          maxWidth="md"
          onHide={() => setEligiblityModal(false)}
          centered
          bodyClassName="pt-md-4 pt-12 px-0 mt-2"
        >
          <NotEligibleModal />
        </AppModal>
      )}

      {alreadyWonTheTicket && (
        <AppModal
          maxWidth="md"
          onHide={() => setAlreadyWonTheTicket(false)}
          centered
          bodyClassName="pt-md-4 pt-12 px-0 mt-2"
        >
          <AlreadyWonTheTicket />
        </AppModal>
      )}

      {campaignEnded && (
        <AppModal
          maxWidth="md"
          onHide={() => setCampaignEnded(false)}
          centered
          bodyClassName="pt-md-4 pt-12 px-0 mt-2"
        >
          <CampaignEnded />
        </AppModal>
      )}
    </>
  );
};

export default Header;
