import { CDS_PIXEL_ID } from "../config/constant";
import { getIpDetails } from "./helper";

export const cdsPixelID = CDS_PIXEL_ID;

export const getGeoData = () => {
  const ipDetails = getIpDetails();
  let ipAddress = "",
    stateCode = "",
    city = "",
    zip = "",
    countryCode = "";
  if (ipDetails !== null) {
    try {
      // ip addresss
      if (ipDetails.hasOwnProperty("query")) {
        ipAddress = ipDetails["query"];
        // Your code that uses 'ipAddress' goes here
        console.log("ipAddress", ipAddress);
      } else {
        console.error("'query' property not found in 'ipDetails'");
      }

      // stateCode
      if (ipDetails.hasOwnProperty("region")) {
        stateCode = ipDetails["region"];
      } else {
        console.error("'region' property not found in 'ipDetails'");
      }

      // city
      if (ipDetails.hasOwnProperty("city")) {
        city = ipDetails["city"];
      } else {
        console.error("'city' property not found in 'ipDetails'");
      }

      // zip
      if (ipDetails.hasOwnProperty("zip")) {
        zip = ipDetails["zip"];
      } else {
        console.error("'zip' property not found in 'ipDetails'");
      }

      //country code
      if (ipDetails.hasOwnProperty("countryCode")) {
        countryCode = ipDetails["countryCode"];
      } else {
        console.error("'country code' property not found in 'ipDetails'");
      }
    } catch (error) {
      console.error("Error parsing 'ipDetails' JSON:", error);
    }
  } else {
    console.error("'ipDetails' key not found in localStorage");
  }

  const data = {
    ip: ipAddress,
    geo_state_province_code: stateCode,
    geo_city_name: city,
    geo_country_code: countryCode,
    geo_postal_code: zip && zip.length == 4 ? zip : "5555",
  };
  return data;
};

export const BuyNowCDP = async (eventSubType, partnerName) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    partner_name: `${partnerName}`,
    brand_name: "Coca-Cola",
  };
  console.log("cdp buynow", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const onLoadCDP = async (eventSubType, value) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "On load",
    event_sub_type: `${eventSubType}`,
    brand_name: "Coca-Cola",
    ...value,
  };
  console.log("cdp onload", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const stumCamCDP = async (eventSubType) => {
  // const CdpCookie = GetUserIdentifier();
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    brand_name: "Coca-Cola",
  };
  console.log("cdp stumpcamp", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const fieldCDP = async (eventSubType, fieldType, fieldValue) => {
  console.log(fieldValue, "fieldValue1223");
  let field = fieldType.toLowerCase();
  let data;
  if (field == "email") {
    data = {
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
      email: `${fieldValue}`,
    };
    return;
  } else if (field == "gender") {
    data = {
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
      gender: `${fieldValue}`,
    };
  } else if (field == "state") {
    data = {
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
      geo_state_province_code: `${fieldValue}`,
    };
  } else if (field === "city") {
    data = {
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
      geo_city_name: `${fieldValue}`,
    };
  } else if (field === "pincode") {
    data = {
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
      geo_postal_code: `${fieldValue}`,
    };
  } else if (field === "age") {
    data = {
      // 'age' : `${fieldValue}`,
      event_type: "Click",
      event_sub_type: `Edit_${eventSubType}`,
      brand_name: "Coca-Cola",
    };
  } // neede to confirm key
  // const dataCdp = {...preField,data};
  console.log("cdp field", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const HomeCDP = async () => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "Page_view",
    event_sub_type: "Homepage_load",
    brand_name: "Coca-Cola",
  };
  const res = getGeoData();
  const geoData = {
    geo_country_code: res?.geo_country_code,
    geo_state_province_code: res?.geo_state_province_code,
    geo_city_name: res?.geo_city_name,
    geo_postal_code:
      res?.geo_postal_code && res?.geo_postal_code?.length == 4
        ? res?.geo_postal_code
        : "5555",
    ip_address: res?.ip_address,
  };

  const dataCdp = { ...data, ...geoData };
  console.log("cdp Home", dataCdp);
  window.cds_pixel(cdsPixelID, dataCdp);
};
export const ClickButtonCDP = async (
  eventSubType,
  value,
  event_type = "Click"
) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: event_type,
    event_sub_type: `${eventSubType}`,
    brand_name: "Coca-Cola",
    ...value,
  };
  console.log("cdp clickbutton", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};
