import React, { useState, useContext, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale, Tooltip } from "chart.js";
import { getLineGraph } from "../service";
import moment from 'moment'
import useTranslation from "../utils/hooks/use-translation";
Chart.register(CategoryScale, Tooltip);

const LineChart = () => {
  const [chartData, setChartData] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const {t} = useTranslation()

  useEffect(() => {
    getChartDetails();
  }, []);
const dummydata = [
  {
      "Date": "2023-07-14T00:00:00",
      "Percentage": 100,
      "Name": "India vs Pakistan Demo"
  },
  {
      "Date": "2023-07-15T00:00:00",
      "Percentage": 77,
      "Name": ""
  },
  {
      "Date": "2023-07-17T00:00:00",
      "Percentage": 80,
      "Name": ""
  },
  {
      "Date": "2023-07-18T00:00:00",
      "Percentage": 76,
      "Name": "18_test"
  },
  {
      "Date": "2023-07-19T00:00:00",
      "Percentage": 70,
      "Name": ""
  },
  {
      "Date": "2023-07-20T00:00:00",
      "Percentage": 58,
      "Name": ""
  },
  {
      "Date": "2023-07-21T00:00:00",
      "Percentage": 54,
      "Name": ""
  },
  {
      "Date": "2023-07-22T00:00:00",
      "Percentage": 53,
      "Name": ""
  },
  {
      "Date": "2023-07-23T00:00:00",
      "Percentage": 54,
      "Name": ""
  },
  {
      "Date": "2023-07-25T00:00:00",
      "Percentage": 48,
      "Name": "Tips"
  },

]
  const getChartDetails = async () => {
    try {
      const response = await getLineGraph();
      const data = response && response.data && response.data.Data;
      setAllChartData(data);
      const percentages = data?.map((item) => item.Percentage);
      setChartData(percentages.map(parseFloat));
    
    } catch (error) {
      console.error('Error updating profile name:', error);
    }
    setTimeout(() => {
    }, 500);
  };


  // get point radius based on name
  const getPointRadius = (context) => {
    let index = context && context.index;
    let name = allChartData[index]?.Name;
    let style = name ? 5 : 0
    return style; // Default to 'circle' if you don't have a specific condition
  }

  // get point hover radius based on name
  const getPointHoverRadius = (context) => {
    let index = context && context.dataIndex;
    let name = allChartData[index]?.Name;
    let style = name ? 5 : 0
    return style; // Default to 'circle' if you don't have a specific condition
  }

  // Step 1: Convert date labels to date objects
  const dates = allChartData.map((el) => {
    let date = new Date(el.Date)
    let format_date = moment(date).format('DD')
    return  format_date
  });

  // Step 2: Create chart data array with 'x' and 'y' properties
  const chartDataPoints = dates.map((date, index) => ({ x: date, y: chartData[index] }));
  const options = {
    scales: {
      x: {
        grid: {
          offset: true,
          padding: {
            left: 20, // Adjust the left padding as needed
            right: 20, // Adjust the right padding as needed
          },
          color: 'white', // Change the color of the grid lines
        },
        ticks: {
          display: false, // Hide the horizontal labels
          color: 'white', // Change the color of the x-axis labels
        },

      },
      y: {
        min: 0,
        max: 100,
        grid: {
          offset: true,
          padding: {
            top: 20, // Adjust the top padding as needed
            bottom: 20, // Adjust the bottom padding as needed
          },
          color: 'white', // Change the color of the grid lines
        },
        ticks: {
          color: 'white', // Change the color of the y-axis labels
          callback: (value) => `${value}%`, // Add the callback function to append '%' to the y-axis labels
          stepSize: 20, // Set the difference between ticks to 20
          font: {
            weight: "bolder",
          },
        },

      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#560C10', // Set tooltip background color to red with opacity
        titleColor: 'white', // Set tooltip title color to white
        bodyColor: 'white', // Set tooltip body color to white
        displayColors: false, // Hide color indicators in tooltip
        callbacks: {
          title: (context) => {
            let index = context[0].dataIndex;
            return allChartData[index]?.Name || 'Ban vs Ind';
          }, // Set the tooltip title
          label: () => '', // Add '%' to the tooltip label
          labelTextColor: () => 'white', // Set the tooltip label text color to white
        },
        position: 'nearest', // Position the tooltip below the marker
        caretPadding: 15, // Adjust the space between the tooltip and the marker
        xAlign: "center",
        yAlign: "bottom",
        titleAlign: "center",
      },
    },

    elements: {
      point: {
        backgroundColor: 'white', // Change the color of the data points
        borderColor: 'white', // Change the border color of the data points
        pointStyle: 'circle', // Set the point style to 'circle'
        radius: 5, // Set the radius of the points
        hoverRadius: 8, // Set the hover radius of the points
        //pointStyle: getPointStyle
      },
      line: {
        borderColor: 'white', // Change the color of the line
      },
    },
  };
  //const labels = ['100%', '80%', '60%', '40%', '20%', '0%'];
  const labels = dates
  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        data:chartDataPoints,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 158, 219, 0.5)',
        borderWidth: 2,
        pointBackgroundColor: 'white', // Set point color to red
        // pointRadius: getPointRadius, // Set point radius to 5 to display as dots
        // pointHoverRadius: getPointHoverRadius, // Set hover radius
      },
    ],
  };
  const chartWidth = 500; // Set the desired width of the chart
  const chartHeight = 300; // Set the desired height of the chart
  return (
    <div>
      <div className="text-center">
        <h2 className="meter-title">{t.daily_senti} </h2>
        <h4 className="meter-sub-heading">
          {t.track_daily}
        </h4>
      </div>
      <Line
        data={data}
        options={options}
        width={chartWidth}
        height={chartHeight}
      />
    </div>
  );
};

export default LineChart;
