import React from 'react';
import useTranslation from '../../utils/hooks/use-translation';

// Define the GoldCoinVictory component
const GoldCoinVictory = ({ setShowMyWallet, setVictoryCoin, closePopup }) => {
  const handleSeeCollection = () => {
    setShowMyWallet(true);
    setVictoryCoin('');
    closePopup();
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center pt-4">
        {' '}
        {/* Container with text centered and top padding */}
        <img
          src="assets/images/Ticket-Coin-new.png" // Image source of a platinum coin
          alt="Ticket Coin" // Alternate text for the image
          className="modal-coins" // CSS class applied to the image
        />
        <h3 className="modal-title pb-9 mb-3 pl-md-5 pr-md-5 pt-5">
          {' '}
          {/* Heading with CSS classes for styling */}
          {t.fielded} {t.win_ticket_coin}
        </h3>
        <a
          className="btn btn-danger d-block ml-md-12 mr-md-12 mb-md-8 mb-2 ml-4 mr-4 d-flex align-items-center justify-content-center" // Button with CSS classes for styling
          onClick={handleSeeCollection}>
          {t.see_collection}
        </a>
      </div>
    </>
  );
};

export default GoldCoinVictory;
