import React from "react";
import { Col, Row } from "react-bootstrap";

export default function HeroBannerContest(props) {
  const {image,heading,subHeading,subParagraph,alt,paragraph} = props
  return (
    <>
      {/* Hero section */}
      <section className="hero">
        <div className="bg bg-pattern-2 d-flex align-items-center">
          <div className="container-fluid">
            {/* Row for alignment */}
            <Row className="align-items-center justify-content-center">
              {/* Column for the hero image */}
              <Col md={6} className="hero-img-wrapper text-md-right text-center">
                {/* Hero image */}
                <img
                  src={image}
                  alt={alt}
                  className="img-fluid"
                />
              </Col>

              {/* Column for the hero text */}
              <Col md={6} className="d-flex justify-content-md-start justify-content-center">
                <div className="text-center pt-md-8">
                  {/* Hero text */}
                  <h2 className="mb-2 bn-heading">
                    {heading}
                    <br /> {subHeading}
                  </h2>
                  <h3 className="display-1 mb-2">{paragraph}</h3>
                  <h2 className="bn-margin">{subParagraph}</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}
