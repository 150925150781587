import { State, City } from "country-state-city";
import { COUNTRY_CODE } from "../config/appConfig";

export const setUserDetails = (dummy_data) => {
  localStorage.setItem("user_details", JSON.stringify(dummy_data));
};

const year = new Date().getFullYear();

export const currentYear = year.toString();

//Check user is able to claim or not
export const ableToclaim = (victoryCoin) => {
  return !Object.values(victoryCoin).some((value) => value === 0);
};

export const handleShare = async (title, text, image, url) => {
  console.log(text, "text");
  try {
    if (navigator.share) {
      const shareData = {
        title: title,
        text: text,
      };

      if (url && url.trim() !== "") {
        if (image && image.trim() !== "") {
          const response = await fetch(image);
          const blob = await response.blob();
          const filesArray = [
            new File([blob], "image.png", { type: "image/png" }),
          ];
          shareData.files = filesArray;
        }
        shareData.url = url;
      }

      await navigator.share(shareData);
      // Handle successful share
    } else {
      // Web Share API not supported
      // Display a tooltip or fallback share options
    }
  } catch (error) {
    console.error("Error sharing:", error);
    // Handle error while sharing
  }
};

//mobile number validation
export const handleKeyPressMobileNumber = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 11);

  // Check if the filtered value is '00000000' and handle accordingly
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid mobile number");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

//pin code validation
export const handleKeyPressPinCode = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 4);

  // Check if the filtered value is '00000000' and handle accordingly
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid Postal code");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

/**
 * @method normalizedName
 * @description return simple english letter (unicode) of special letters like latin letters
 * @param {*} str
 * @returns
 */
export const normalizedName = (str) =>
  str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

/**
 * @method getStateList
 * @description return state list
 * @returns
 */
export const getStateList = () => {
  const states = State.getStatesOfCountry(COUNTRY_CODE);
  // Bangladesh has these divisions
  const BD_Divisions = ["Dhaka", "Khulna", "Rajshahi", "Rangpur", "Sylhet"];
  // taking only which has cities
  const has_city = states
    .filter((s) => BD_Divisions.find((d) => s.name.search(d) !== -1))
    .filter((s) => s.name.search("District") !== -1);
  return has_city;
};

/**
 * @method getCityList
 * @description return cities of the state
 * @param {*} stateCode
 */
export const getCityList = (stateCode = "") => {
  const cities = City.getCitiesOfState(COUNTRY_CODE, stateCode);
  // converting special char to unicode
  const normalizedCities = cities.map((s) => ({
    ...s,
    name: normalizedName(s.name),
  }));
  return normalizedCities;
};

//limit the input field

export const handleInputLimitedLength = (event, minLength, maxLength) => {
  const inputValue = event.target.value;

  // Limit the input value to the maximum length
  let limitedValue = inputValue.slice(0, maxLength);

  // Enforce minimum length
  if (limitedValue.length < minLength) {
    limitedValue = inputValue.slice(0, minLength);
  }

  // Update the input value with the limited value
  event.target.value = limitedValue;
};

//get the ip details from local storage
export const getIpDetails = () => {
  const ipData = localStorage.getItem("ipDetails")
    ? JSON.parse(localStorage.getItem("ipDetails"))
    : null;
  return ipData;
};

export const getCDSPixelId = async () => {
  //const fetchPixelId = await window.cookieStore.get('__cds_pixel_id');
  if (document?.cookie) {
    const fetchPixelId =
      document &&
      document?.cookie
        ?.split(";")
        .find((row) => row.startsWith("__cds_pixel_id="))
        ?.split("=")[1];
    console.log(fetchPixelId, "fetchPixelId");
    //const hfSessionKey = fetchPixelId['value'];
    return fetchPixelId;
  }
};

//validations for name
export const handleKeyPressName = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressNumberAllowed = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^0-10]/g, "");
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressPincode = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 4 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 4);

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressAge = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 3 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 3);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

//download the pdf
export const downloadPdf = (pdfUrl) => {
  if (pdfUrl) {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    link.download = "claimformdetails.pdf";

    // Append the anchor element to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the anchor element
    document.body.removeChild(link);
  }
};

export const roundedValue = (decimalValue) => {
  if (decimalValue) {
    let roundOff = Math.round(decimalValue);
    return roundOff;
  } else {
    return "";
  }
};

export const getClassByname = (language) => {
  let className = "en_global";
  if (language === "bn") {
    //for bangali
    className = "bn_global";
  }
  return className;
};

//prevent right click
export const preventRightClick = () => {
  if (typeof window === "undefined" || typeof document === "undefined") return;
  // Function to handle the contextmenu event
  function handleContextMenu(event) {
    event.preventDefault(); // Prevent the default right-click context menu
  }
  // Add the event listener to the document
  document.addEventListener("contextmenu", handleContextMenu);
};

export const disableInspect = () => {
  if (typeof window !== "undefined") {
    // Disable inspect element
    document.onkeydown = (event) => {
      console.log("event", event.ctrlKey, event.shiftKey, event.keyCode);
      if (event.keyCode === 123) {
        return false;
      } else if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.keyCode === 73 || event.keyCode === 67)
      ) {
        return false;
      }
    };
    // Disable right click
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }
};

export const getCountryNameBasedOnCode = (code) => {
  const country = [
    { code: "AF", label: "Afghanistan" },
    { code: "AU", label: "Australia" },
    { code: "BD", label: "Bangladesh" },
    { code: "GB", label: "England" },
    { code: "IN", label: "India" },
    { code: "NL", label: "Netherlands" },
    { code: "NZ", label: "New Zealand" },
    { code: "PK", label: "Pakistan" },
    { code: "ZA", label: "South Africa" },
    { code: "LK", label: "Sri Lanka" },
  ];
  const selectedCountry = country.find((item) => item.code === code);

  if (selectedCountry) {
    return selectedCountry.label;
  } else {
    return ""; // Return a default value if the code is not found
  }
};
const isStringInJSONFormat = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};
const getMessage = (language, message) => {
  console.log(language,"language",message)
  const currentLanguage = language ? language : "en";
  const isJSONFormat = isStringInJSONFormat(message);
  if (isJSONFormat) {
    let parsed_value = message && JSON.parse(message);
    console.log(parsed_value,"parsed_value")
    console.log("errorRes", parsed_value.currentLanguage);
    if (parsed_value) {
      return parsed_value[currentLanguage];
    } else {
      return "";
    }
  } else {
    console.log("eeror in parse json");
    return "";
  }
};

//handle the error message with muti languge
export const handleErrorMessages = (currentLanguage, error, setFieldError) => {
  console.log(currentLanguage,"currentLanguage12")
  let errorRes = error.response && error.response.data;
  let errorarray =
    errorRes.Data && Array.isArray(errorRes.Data) && errorRes.Data.length
      ? errorRes.Data
      : "";
  if (errorarray) {
    errorarray.map((el) => {
      let message = getMessage(currentLanguage, el.Message);
      setFieldError(el.PropertyName, message ? message : el.Message);
      return null;
    });
  } else {
    let message = getMessage(currentLanguage, errorRes.Message);
    setFieldError("Message", message ? message : errorRes.Message);
  }
};

export const isCampaignEnded = () => {
  const currentDate = new Date();
  const targetDate = new Date('2023-11-05T00:00:00');
  const isEnded = currentDate >= targetDate
  console.log(currentDate,"date",targetDate,"condition",isEnded)
  return isEnded;
};

export const isFoodPandaEnded = () =>{
  const currentDate = new Date();
  const targetDate = new Date('2023-11-09T00:00:00');
  const isFoodPandaEnded = currentDate >= targetDate;
  console.log(isFoodPandaEnded,"isFoodPandaEnded",currentDate,targetDate)
  return isFoodPandaEnded
}