import React from "react";
import { Modal } from "react-bootstrap";

export default function AppModal({
  show = true, // Flag to determine whether the modal is shown or hidden
  onHide, // Function to handle the modal's close event
  children, // Content to be rendered inside the modal body
  size = "md", // Size of the modal ("sm" for small, "lg" for large, or "md" for medium - default)
  headerClassName = "", // Additional CSS class(es) to be applied to the modal header
  bodyClassName = "", // Additional CSS class(es) to be applied to the modal body
  centered = true, // Flag to determine whether the modal is vertically centered
  modalClassName, // Additional CSS class(es) to be applied to the modal
  closeButton = true,
  backdrop=true,
  ...rest // Rest of the props that can be passed to the underlying Modal component
}) {
  return (
    <Modal
      onHide={onHide} // Event handler for the modal's close event
      className={modalClassName} // Event handler for the modal's close event
      show={show} // Flag to determine whether the modal is shown or hidden
      size={size} // Size of the modal ("sm" for small, "lg" for large, or "md" for medium)
      centered={centered} // Flag to determine whether the modal is vertically centered
      backdrop={backdrop}
      {...rest} // Spread the rest of the props to the underlying Modal component
    >
      <Modal.Header closeButton={closeButton} className={headerClassName}></Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
    </Modal>
  );
}
