import React from "react";
import { Row, Col } from "react-bootstrap";
import useTranslation from "../../utils/hooks/use-translation";

function ClaimCard() {
  const {t} = useTranslation()
  return (
    <section className="how-win-card claim-section  pt-4 pt-md-8" id="how_to_win">
      <div className="container-fluid">
        {/* Heading */}
        <h2 className="text-center mb-md-8 mb-4">{t.how_to_claim}</h2>
        <Row className="flex-nowrap">
          <Col className="claim-card">
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className=" img-fluid mx-auto"
                    src="assets/images/How-Claim/fill-form.png"
                    alt="fill form"
                  />
                </div>
                <h5 className="card-title">{t.step_1}</h5>
                <p className="card-text">
                {t.fill_claim}
                </p>
              </div>
            </div>
          </Col>

          {/* Card 2 */}
          <Col className="claim-card">
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className=" img-fluid mx-auto"
                    src="assets/images/How-Claim/ticket-email.svg"
                    alt="pickup point"
                  />
                
                </div>

                <h5 className="card-title">{t.step_2}</h5>
                <p className="card-text">
                {t.step_choose} <span className="d-md-block"></span>{t.step_ticket_l}
                </p>
              </div>
            </div>
          </Col>

          {/* Card 3 */}
          <Col className="claim-card">
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className=" img-fluid mx-auto"
                    src="assets/images/How-Claim/collect-ticket.png"
                    alt="collect ticket"
                  />
                </div>
                <h5 className="card-title">{t.step_3}</h5>
                <p className="card-text">{t.step_collect}<span className="d-block">{t.the_pickup}</span></p>
              </div>
            </div>
          </Col>

        </Row>
        {/* <h4 className="text-dark text-center pt-md-3 pt-2 mb-md-2 mb-0">{t.pickup}</h4>
<h5 className="text-center">{t.Dhaka}, {t.Chittagong} ,{t.Sylhet}, {t.Khulna}, {t.Rajshahi},<br/> {t.Rangpur}, {t.Barisal}, {t.Mymensingh}</h5> */}
      </div>
    </section>
  );
}

export default ClaimCard;
