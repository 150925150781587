// Import the `createSlice` function from `@reduxjs/toolkit`
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWalletDetailsById, saveTicketWinner } from "../../service";
import { toggleLoading } from "./commonSlice";
// Define the initial state for the authentication slice
const initialState = {
  walletDetails: null,
};
export const fetchWalletByUserId = createAsyncThunk(
  "wallet/fetchWalletByUserId",
  async (userId, thunkAPI) => {
    // thunkAPI.dispatch(toggleLoading(true));
    const Id = userId || thunkAPI.getState().auth.userDetails?.Id;
    const response = await getWalletDetailsById({ Id });
    // thunkAPI.dispatch(toggleLoading(false));
    return { walletData: response.data?.Data };
  }
);

// Create the authentication slice using `createSlice`
export const authSlice = createSlice({
  name: "wallet", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for handling successful login
    setWallet: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.walletDetails = state.walletDetails
        ? { ...state.walletDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWalletByUserId.fulfilled, (state, action) => {
      state.walletDetails = action.payload;
    });
    builder.addCase(fetchWalletByUserId.rejected, (state, action) => {
      console.log("action: ", action);
      console.log("state: ", state);
    });
  },
});

// Extract the action creators generated by `createSlice`
export const { setWallet } = authSlice.actions;

// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
