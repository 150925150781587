import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/slice/commonSlice";
import translations from "../translation";

function useTranslation() {
  const dispatch = useDispatch();
  
  const currentLanguage = useSelector((state) => state.common.currentLanguage);

  const t = useMemo(() => {
    return translations[currentLanguage || "en"];
  }, [currentLanguage]);

  const changeLanguage = useCallback(
    (lang = "en") => {
      dispatch(setLanguage(lang));
    },
    [dispatch]
  );

  return { t, changeLanguage, currentLanguage };
}

export default useTranslation;
