export const API = {
  getData: `/api/User/SendOTP`,
  postMobileVerifyOtp: `/api/User/VerifyOTP`,
  registerData: `/api/User/Register`,
  updateProfileName: `api/User/UpdateName`,
  updateProfile: `/api/User/UpdateProfielImg`,
  updateProfileEmail: `api/User/UpdateEmail`,
  updateProfileAge: `api/User/UpdateAge`,
  updateProfileGender: `api/User/UpdateGender`,
  updateProfileCity: `api/User/UpdateCity`,
  updateProfileState: `api/User/UpdateState`,
  updateProfileStateCity: `api/User/UpdateStateAndCity`,
  updateProfilePinCode: `api/User/UpdatePincode`,
  voteNowForFlag: `/api/Voting/VoteForWorldCup`,
  updateProfileImage: `/api/User/updateProfilellmg`,
  getUserById: `/api/User/GetUserById`,
  tossNowForFlag: `/api/Voting/GetDailyTossCoin`,
  getDetailsByMap: `GET /api/Voting/GetVotesForWorldCup`,
  sendReferCode: `/api/User/SendRefCode`,
  sendClaimMerch: `/api/Voting/ClaimMerch`,
  verifyreferCode: `/api/User/VeryfyRefCode`,
  worldcupVotes: `/api/Voting/GetVotesByTopCites`,
  linegraphDetails: `/api/Voting/GetWeeklyVotesForLineGraph`,
  checkMatchClaim: `/api/Voting/CheckMerchClaimedToday`,
  ticketWinnerCount: `/api/Voting/GetTicketWinnerCount`,

  

  //unique code api (new)
  validateUniqueCode: `/api/VotingCT/VerifyAndRedeemMixCode`,
  saveTicketWin: `/api/VotingCT/SaveTicketWinner`,
  sendClaimTicket: `/api/VotingCT/ClaimTicket`,
  getWalletById: `/api/VotingCT/GetWalletDetailsById`,
  getTicketWinnerUsers: `api/VotingCT/GetTicketWinnerUsers`,
  downloadticketClaimDetails : `api/VotingCT/UploadS3TicketClaimDetailsAsync`,

  //foodpanda journey api
  FoodPandaVerifyAndRedeemMixCode: `/api/VotingCT/FoodPandaVerifyAndRedeemMixCode`,

  //unique code (old)
  // getWalletById: `/api/Voting/GetWalletDetailsById`,
  // validateUniqueCode: `/api/Voting/VerifyAndRedeemMixCode`,
  // saveTicketWin: `/api/Voting/SaveTicketWinner`,
  // sendClaimTicket: `/api/Voting/ClaimTicket`,
  // getTicketWinnerUsers: "/api/Voting/GetTicketWinnerUsers",
  // downloadticketClaimDetails: `/api/Voting/UploadS3TicketClaimDetailsAsync`,
};
