import axios from "axios";
import { API_BASE_URL } from "../config/appConfig";

class AxiosHelper {
  constructor() {
    this.instance = axios.create({
      baseURL: API_BASE_URL,
      timeout: 50000,
      adapter:['xhr','http','https']
    });
  }
  async get(endpoint, params) {
    try {
      const response = await this.instance.get(endpoint, {
        params,
        headers: this.getAuthHeader(),
        
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async post(endpoint, data) {
    try {
      const response = await this.instance.post(endpoint, data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async put(endpoint, data) {
    try {
      const response = await this.instance.put(endpoint, data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async delete(endpoint) {
    try {
      const response = await this.instance.delete(endpoint, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  getAuthHeader() {
    const headers = {};
    const dataForToken = localStorage.getItem("okta-token-storage");
    const parsedData = JSON.parse(dataForToken);
    let authToken =
      parsedData && parsedData.idToken && parsedData.idToken.idToken;
    if (authToken) {
      headers.Authorization = authToken;
    }
    return headers;
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AxiosHelper();
