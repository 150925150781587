/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useRef, useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  getUserDetailsById,
  updateProfileName,
  updateProfile,
  updateProfilePinCode,
  updateProfileAge,
  updateProfileGender,
  updateProfileEmail,
  updateProfileStateCity,
} from "../../../service";

import { LoaderContext } from "../../loader/LoaderContext"; // Import the LoaderContext

import InfoModal from "../../model/InfoModal"; // Import the pop-up component

import {
  setUserDetails,
  ableToclaim,
  handleKeyPressName,
  handleKeyPressAge,
  handleKeyPressNumberAllowed,
  handleKeyPressPincode,
  handleErrorMessages,
  getCDSPixelId,
  isCampaignEnded,
} from "../../../utils/helper";

import { avatarImages, divisionOptions } from "../../../config/constant";

import MyWallet from "../my-wallet/MyWallet";

import ClaimTicketFormModal from "../../model/ClaimTicketFormModal";

import { setUser } from "../../../redux/slice/authenticationSlice";

import useAuth from "../../../utils/hooks/use-auth";

import useTranslation from "../../../utils/hooks/use-translation";

import FieldForm from "./field-form";

import DisplayField from "./display-field";

import { profileFieldValidation } from "../../../utils/validations";

import { useLocation } from "react-router-dom";

import {  fieldCDP } from "../../../utils/cdsHelper";

// Regular expression to match special characters

function ProfileMenu({ walletDetails, getWalletDetails,setCampaignEnded }) {
  const dispatch = useDispatch();

  const { userDetails } = useAuth();

  const location = useLocation();

  const { t } = useTranslation();

  const { currentLanguage } = useTranslation();

  const [editing, setEditing] = useState([]);

  const { toggleLoader } = useContext(LoaderContext);

  const [showWallet, setShowMyWallet] = useState(false);

  const [showClaimTicket, setShowClaimTicket] = useState(false);

  const [profileImage, setprofileImage] = useState();

  const [isEditingImage, setIsEditingImage] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const [fielderror, setFieldError] = useState("");

  const [popup, setPopupInfo] = useState({
    title: "",

    content: "",
  }); // State to manage the pop-up properties

  const walletData = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData
  );

  const tooltipRef = useRef(null);

  let victoryCoin = walletDetails && walletDetails.VictoryCoinDetail;

  const enbleButton = walletDetails?.walletData;

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsTooltipOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const initialValues = {
    name: userDetails && userDetails.Name ? userDetails.Name : "--",

    email: userDetails && userDetails.Email ? userDetails.Email : "--",

    gender: userDetails && userDetails.Gender ? userDetails.Gender : "--",

    city: userDetails && userDetails.City ? userDetails.City : "--",

    state: userDetails && userDetails.State ? userDetails.State : "--",

    pinCode:
      userDetails && userDetails.Pincode !== 0 ? userDetails.Pincode : "--",

    mobile: userDetails && userDetails.MobileNo ? userDetails.MobileNo : "--",

    age: userDetails && userDetails.Age !== 0 ? userDetails.Age : "--",

    ProfileImg:
      userDetails && userDetails.ProfileImg
        ? userDetails.ProfileImg
        : "assets/images/avatar.png",
  };

  const [initialValue, setInitialValue] = useState(initialValues);

  useEffect(() => {
    setInitialValue(initialValues);

    setprofileImage(userDetails?.ProfileImg);

    getUserData();

    setprofileImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.dataLayer.push({ event: "bdlogoutbtn" });
    if (location.search == "?qr=true") {
      window.location.assign("?qr=true");
    }else if(location.search == "?Ref=foodpanda"){
      window.location.assign('?Ref=foodpanda');
    } else {
      window.location.assign("/");
    }
  };

  //enable claim button

  const enableClaimButton =enbleButton &&
    enbleButton?.Ticket !== enbleButton?.TicketClaimed;

  /**

   * Fetch user data from the server and update the state with the retrieved details

   */

  const getUserData = async () => {
    if (userDetails) {
      const response = await getUserDetailsById({
        Id: userDetails && userDetails.Id,
      });

      if (response && response.status === 200) {
        let data = response && response.data && response.data.Data;

        // saving user data in redux

        dispatch(setUser(data));

        setUserDetails(data);

        setprofileImage(data.ProfileImg);

        let initialData = {
          name: data && data.Name,

          email: data && data.Email,

          gender: data && data.Gender,

          city: data && data.City,

          state: data && data.State,

          pinCode: data && data.Pincode,

          mobile: data && data.MobileNo,

          age: data && data.Age,

          ProfileImg: data && data.ProfileImg,
        };

        setInitialValue(initialData);

        if (
          data.ProfilePercent === 100 &&
          !data.IsAlreadyProfileCompleteCoinWon
        ) {
          setShowDropdown(false);

          getWalletDetails();
        }
      }
    }
  };

  /**

   * Handle editing the profile image

   * @param {string} image - The new profile image

   */

  const handleEditProfileImage = async (image) => {
    try {
      toggleLoader();

      const requestData = {
        Id: userDetails && userDetails.Id,

        ProfileImg: image,
      };

      const response = await updateProfile(requestData); // Make the API call to update the profile image

      if (response && response.status === 200) {
        setShowDropdown(true);

        // Profile image updated successfully

        // You can perform any additional actions or update the UI accordingly
      }
    } catch (error) {
      console.log("Error:", error);

      // Handle the error as needed
    }

    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  const closePopup = () => {
    setPopupInfo({
      title: "", // Reset the title

      content: "", // Reset the content
    });
  };

  const handleClose = () => {
    setShowMyWallet(false);
  };

  const handleImageSelection = (imageUrl) => {
    setSelectedImage(imageUrl);

    setIsEditingImage(false);

    setprofileImage(imageUrl); // Update the profileImage state with the selected image URL

    setIsTooltipOpen(false); // Close the tooltip

    handleEditProfileImage(imageUrl);
  };

  const getMaskedMobileNumber = (mobileNumber) => {
    const visibleDigits = 4; // Number of visible digits in the masked mobile number
    const visiblePart = mobileNumber.slice(0, visibleDigits);
    const maskedPart = "X".repeat(mobileNumber.length - visibleDigits);
    return visiblePart + maskedPart;
  };

  const renderTooltip = () => (
    <Tooltip
      id="image-selection-tooltip"
      className="tooltip-profile-avatar"
      show={isTooltipOpen}
    >
      <div className="avatar-options">
        {avatarImages.map((imageUrl, index) => {
          return (
            <img
              key={index}
              src={imageUrl}
              alt={`Avatar ${index}`}
              className={`avatar-option ${
                selectedImage === imageUrl ? "selected" : ""
              }`}
              onClick={() => {
                handleImageSelection(imageUrl);

                setIsTooltipOpen(false); // Close the tooltip
              }}
            />
          );
        })}
      </div>
    </Tooltip>
  );

  const handleDropdownToggle = (isOpen) => {
    setShowDropdown(isOpen);

    setFieldError({ Message: "" });

    setEditing([]);
  };

  //manage all the api calls using this function

  const handleEditField = async (
    field,
    editingState,
    setEditingState,
    updateFunction
  ) => {
    let cdpData = {}
    const hfSessionKey = await getCDSPixelId();
    if (!editingState) {
      setEditingState([field]);
    } else {
      const els = document.querySelectorAll(".tu-user-input.edit");

      let fieldVal = "";

      for (let c of els) {
        if (c.name?.toLowerCase() === field?.toLocaleLowerCase()) {
          fieldVal = c.value;
        }
      }
      console.log(field,"field123" , fieldVal, "fieldVal");
      const requestData = {
        [field]: fieldVal,
        HfSession: hfSessionKey ? hfSessionKey : "" ,
        Id: userDetails && userDetails.Id,
      };
console.log(fieldVal,"fieldVal")
      try {
        toggleLoader();
        const response = await updateFunction(requestData);
        if (response) {
          setEditingState([]);
          getUserData();
          setFieldError("");
          //cdp integration
          if (field && fieldVal) {
            if ([field] == "Email") {
              cdpData = {
                email: fieldVal,
              };
            } else if([field] == "Pincode"){
              cdpData = {
                geo_postal_code: fieldVal,
              };
            } else {
              cdpData = {
                [field]: fieldVal,
              };
            }
          }
          console.log(fieldVal, "fieldVal");
          // ClickButtonCDP(`Edit_${[field]}`, cdpData);
          fieldCDP(field,field,fieldVal)
        }
      } catch (error) {
        let errorRes = error.response && error.response.data;

        let errorarray =
          errorRes?.Data && Array.isArray(errorRes.Data) && errorRes.Data.length
            ? errorRes.Data
            : "";

        if (errorarray) {
          errorarray.map((el) => {
            setFieldError({ [el.PropertyName]: el.Message });
            return null;
          });
        } else {
          //toast.error(errorRes.Message)
          const errorMessage = JSON.parse(errorRes?.Message);
          setFieldError({ Message: errorMessage[currentLanguage] });
        }
      }

      setTimeout(() => {
        toggleLoader();
      }, 500);
    }
  };

  //manage the edit email functionality with common function

  /**

   * Handle editing the email field in the user profile

   */

  const handleEditEmail = () => {
    handleEditField("Email", editing, setEditing, updateProfileEmail);
  };

  const handleEditAge = () => {
    handleEditField("Age", editing, setEditing, updateProfileAge);
  };

  const handleEditGender = () => {
    handleEditField("Gender", editing, setEditing, updateProfileGender);
  };

  const handleEditPinCode = () => {
    handleEditField("Pincode", editing, setEditing, updateProfilePinCode);
  };

  const validationSchemas = {
    Name: profileFieldValidation(t).Name,
    Email: profileFieldValidation(t).Email,
    Gender: profileFieldValidation(t).Gender,
    State: profileFieldValidation(t).State,
    Pincode: profileFieldValidation(t).Pincode,
    MobileNo: profileFieldValidation(t).MobileNo,
    Age: profileFieldValidation(t).Age,
    ProfileImg: profileFieldValidation(t).ProfileImg,
  };


  return (
    <div>
      <NavDropdown
        // title={<img className="thumbnail-image" src="assets/images/profile-image.png" alt="user pic" />}

        title={
          <img
            className="thumbnail-image"
            src={profileImage ? profileImage : "assets/images/avatar.png"}
            alt="user pic"
            style={{ maxHeight: "37px", height: "100%", width: "37px" }}
          />
        }
        id="basic-nav-dropdown"
        className="tu-profile-dropdown"
        onToggle={handleDropdownToggle}
        show={showDropdown}
      >
        <ul className="tu-user-details">
          <li>
            <div className="apper">
              <div ref={tooltipRef} className="avtatBox mb-1 w-100">
                <OverlayTrigger
                  show={isTooltipOpen}
                  onToggle={setIsTooltipOpen}
                  overlay={renderTooltip()}
                  trigger="click"
                >
                  <div className="avatar-wrapper d-flex">
                    <img
                      className="thumbnail-image ml-0"
                      src={
                        profileImage ? profileImage : "assets/images/avatar.png"
                      }
                      alt="user pic"
                      style={{
                        maxHeight: "40px",

                        height: "100%",

                        width: "40px",
                      }}
                    />

                    {isEditingImage ? (
                      <div className="edit-icon-container">
                        <button
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            const file = event.target.files[0];

                            const imageUrl = URL.createObjectURL(file);

                            handleImageSelection(imageUrl);
                          }}
                        ></button>
                      </div>
                    ) : (
                      <>
                        <input type="text" className="tu-user-input" disabled />

                        <button type="button" className="tu-edit-btn ml-auto">
                          <img
                            src="assets/images/edit-icon.png"
                            alt="edit icon"
                            onChange={(event) => {
                              const file = event.target.files[0];

                              const imageUrl = URL.createObjectURL(file);

                              handleImageSelection(imageUrl);
                            }}
                          />
                        </button>
                      </>
                    )}
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </li>

          <li>
            {/* name field (readonly)  */}

            <div className="tu-input-wrapper  profile-edit">
              <span>{t.name}</span>

              <DisplayField
                name="MobileNo"
                value={initialValues.name}
                validationSchema={validationSchemas.MobileNo}
                setEditing={setEditing}
                hideEdit
              />
            </div>
          </li>

          <li>
            {/* email field  */}

            <div className="tu-input-wrapper">
              <span>{t.email}</span>

              {editing.includes("Email") ? (
                <FieldForm
                  name="Email"
                  type="email"
                  initialValues={{ Email: initialValues.email }}
                  validationSchema={validationSchemas.Email}
                  api={handleEditEmail}
                  getUserData={getUserData}
                  setEditing={setEditing}
                  className="tu-user-input edit"
                />
              ) : (
                <DisplayField
                  name="Email"
                  value={initialValues.email}
                  setEditing={setEditing}
                />
              )}
            </div>
          </li>

          {fielderror && fielderror.Message && (
            <div className="error_message  text-center">
              {fielderror.Message}
            </div>
          )}

          <li>
            {/* mobile number  */}

            <div className="tu-input-wrapper">
              <span>{t.mobile_no}</span>

              <DisplayField
                name="MobileNo"
                value={getMaskedMobileNumber(initialValues.mobile)}
                validationSchema={validationSchemas.MobileNo}
                setEditing={setEditing}
                hideEdit
              />
            </div>
          </li>

          <li>
            {/* age field  */}

            <div className="tu-input-wrapper">
              <span>{t.age}</span>

              {editing.includes("Age") ? (
                <FieldForm
                  name="Age"
                  initialValues={{ Age: initialValues.age }}
                  validationSchema={validationSchemas.Age}
                  api={handleEditAge}
                  getUserData={getUserData}
                  setEditing={setEditing}
                  onInput={handleKeyPressAge}
                />
              ) : (
                <DisplayField
                  name="Age"
                  value={initialValues.age}
                  setEditing={setEditing}
                />
              )}
            </div>
          </li>

          <li>
            {/* gender field  */}

            <div className="tu-input-wrapper gender-wrapper w-100">
              <span>{t.gender}</span>
              {editing.includes("Gender") ? (
                <FieldForm
                  name="Gender"
                  initialValues={{ Gender: initialValues.gender }}
                  validationSchema={validationSchemas.Gender}
                  api={handleEditGender}
                  getUserData={getUserData}
                  setEditing={setEditing}
                  isSelect
                  options={[
                    { label: t.male, value: "Male" },
                    { label: t.female, value: "Female" },
                    { label: t.others, value: "Others" },
                  ]}
                />
              ) : (
                <DisplayField
                  name="Gender"
                  value={initialValues.gender}
                  setEditing={setEditing}
                />
              )}
            </div>
          </li>

          <li>
            {/* Division field  */}

            <div className="tu-input-wrapper gender-wrapper w-100">
              <span>{t.division}</span>

              {editing.includes("State") ? (
                <FieldForm
                  name="State"
                  initialValues={{
                    State: initialValues.state,
                  }}
                  validationSchema={validationSchemas.State}
                  api={(values) => {
                    const selectedDivision = divisionOptions.find(
                      (option) => option.value === values.State
                    );

                    fieldCDP("State", "State", values?.State);
                    fieldCDP(
                      "City",
                      "City",
                      selectedDivision ? selectedDivision.label : ""
                    );

                    return updateProfileStateCity({
                      Id: userDetails && userDetails.Id,

                      City: selectedDivision ? selectedDivision.label : "",

                      State: values.State,

                      Latitude: selectedDivision
                        ? selectedDivision.latitude
                        : 0,

                      Longitude: selectedDivision
                        ? selectedDivision.longitude
                        : 0,
                    });
                  }}
                  getUserData={getUserData}
                  setEditing={setEditing}
                  isSelect
                  options={divisionOptions}
                />
              ) : (
                <DisplayField
                  name="State"
                  value={initialValues.state}
                  setEditing={setEditing}
                />
              )}
            </div>
          </li>

          <li>
            {/* postal code field  */}

            <div className="tu-input-wrapper">
              <span>{t.pincode_mandatory}</span>

              {editing.includes("Pincode") ? (
                <FieldForm
                  name="Pincode"
                  initialValues={{ Pincode: initialValues.pinCode }}
                  validationSchema={validationSchemas.Pincode}
                  api={handleEditPinCode}
                  getUserData={getUserData}
                  setEditing={setEditing}
                  onInput={handleKeyPressPincode}
                />
              ) : (
                <DisplayField
                  name="Pincode"
                  value={initialValues.pinCode}
                  setEditing={setEditing}
                />
              )}
            </div>
          </li>
        </ul>

        <div className="tu-coins-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_wins}</p>
            </div>

            <a
              href={void 0}
              className="btn btn-xs btn-primary"
              onClick={() => {
                setShowMyWallet(true);
                setShowDropdown(false);
              }}
            >
              {t.check_now}
            </a>
          </div>
        </div>

        <div className="tu-coins-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_match_coins}</p>

              <p>{walletData && walletData?.Ticket}</p>
            </div>
          </div>

          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.my_victory_coins}</p>

              <p>{walletData && walletData?.CoinDetail?.CTGoldCoin}</p>
            </div>

            {enableClaimButton && !isCampaignEnded() && (
              <a
                href="#"
                className="btn btn-xs btn-primary claim-ticket"
                onClick={() => {
                  if (enableClaimButton ) {
                    setShowClaimTicket(true);
                    setShowDropdown(false);
                  }
                }}
              >
                {t.claim_ticket}
              </a>
            )}
          </div>
        </div>

        <div className="tu-referrals-section">
          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.referrals_accepted}</p>

              <p>{userDetails && userDetails.AcceptReferred}</p>
            </div>
          </div>

          <div className="tu-content-row">
            <div className="tu-content">
              <p>{t.referrals_pending}</p>

              <p>{userDetails && userDetails.Referred}</p>
            </div>
          </div>
        </div>

        <div className="logout-btn mx-0 w-100">
          <button
            onClick={handleLogout}
            className={`btn btn-sm btn-light mt-3 mb-3 d-flex align-items-center justify-content-center ml-5 bdlogoutbtn`}
          >
            {t.logout}
          </button>
        </div>

        {/* Rest of the code */}
      </NavDropdown>

      {
        <MyWallet
          t={t}
          show={showWallet}
          handleClose={handleClose}
          getWalletDetails={getWalletDetails}
          walletDetails={walletDetails}
          setCampaignEnded={setCampaignEnded}
        />
      }

      {showClaimTicket && (
        <ClaimTicketFormModal
          show={showClaimTicket}
          handleClose={() => setShowClaimTicket(false)}
          t={t}
        />
      )}
    </div>
  );
}

export default ProfileMenu;
