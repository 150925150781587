import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { verifyOtp, sendOtp } from '../../service';
import { Link } from 'react-router-dom';
import {
  getCDSPixelId,
  getIpDetails,
  handleErrorMessages,
  setUserDetails,
} from '../../utils/helper';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import { loginSuccess } from '../../redux/slice/authenticationSlice';
import { useLocation } from 'react-router-dom';
import { ClickButtonCDP, getGeoData } from '../../utils/cdsHelper';
import useTranslation from '../../utils/hooks/use-translation';
const OtpVerificationForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentLanguage } = useTranslation();
  const { toggleLoader } = useContext(LoaderContext);
  const { t, mobileNumber, previousStep, nextStep, setModelContent,startTimer,timer,isButtonDisabled } = props;
  const [successMessage, setSuccessMessage] = useState({
    message: '',
    isSuccess: '',
  });
  console.log(successMessage, 'successMessage');
console.log(isButtonDisabled,"isButtonDisabled")
  /**
   * @method handleGoBack
   * @description Handles going back to the previous step
   */
  const handleGoBack = () => {
    previousStep(2);
  };

  /**
   * @constant modelContent
   * @description The content object for the model, including title, header, and image
   */
  const modelContent = {
    title: t.otp_verification,
    header: (
      <>
        {t.enter_six_digit_code_sent}
        <span className="otp-no"> + 88 {mobileNumber}</span>
        <img
          src="assets/images/edit-icon.png"
          style={{
            cursor: 'pointer',
            width: '20px',
          }}
          onClick={handleGoBack}
          alt="edit arrow"
        />
      </>
    ),
    image: '',
  };

  /**
   * @method useEffect
   * @description React hook that sets the model content when the component mounts
   */
  useEffect(() => {
    setModelContent(modelContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @constant OtpVerificationSchema
   * @description The form validation schema for OTP verification
   */
  const OtpVerificationSchema = Yup.object().shape({
    OTP: Yup.string()
      //.matches(/^[0-9]{6}$/, t.validation_messages.invalid_otp)
      .required(t.validation_messages.required),
    OTP: Yup.string()
      .required(t.validation_messages.OTP_required)
      .min(6, `${t.validation_messages.min_length} 6`)
      .max(6, `${t.validation_messages.max_length} 6`)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_otp),
  });
  /**
   * @method handleSubmit
   * @description Handles the form submission for OTP verification and proceeds to the next step
   * @param {Object} values - The form values containing the OTP
   * @param {Object} formikBag - The Formik form handler methods
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    try {
      toggleLoader();
      const geoData = getGeoData();
      console.log(geoData, 'geoData');
      const hfSessionKey = await getCDSPixelId();
      const requestData = {
        MobileNo: mobileNumber,
        OTP: values.OTP,
        StateCode: geoData?.geo_state_province_code
          ? geoData?.geo_state_province_code
          : 'N/A',
        City: geoData?.geo_city_name ? geoData?.geo_city_name : 'N/A',
        Pincode: geoData?.geo_postal_code ? geoData?.geo_postal_code : 'N/A',
        HfSession: hfSessionKey,
      };
      const response = await verifyOtp(requestData);
      if (response && response.status === 200) {
        const cdpDetails = {
          phone_with_countrycode: mobileNumber,
          ...geoData,
        };
        window.dataLayer.push({ event: 'bdotpcontinuebtn' });
        // ClickButtonCDP('Login', cdpDetails);

        const data = response.data && response.data.Data;
        dispatch(loginSuccess(data));

        if (data) {
          setUserDetails(data);

          if (location.search == '?qr=true') {
            window.location.assign('?qr=true');
          } else if(location.search == "?Ref=foodpanda"){
            window.location.assign('?Ref=foodpanda');
          }else {
            // window.location.assign('/')
          }
        } else {
          nextStep({
            mobile: mobileNumber,
          });
        }
      }
    } catch (error) {
      handleErrorMessages(currentLanguage, error, setFieldError);
    }
    setTimeout(() => {
      toggleLoader();
    }, 500); // Add a small delay before hiding the loader (500ms in this example)
  };

  /**
   * @method handleResendOTP
   * @description Handles the resend OTP request
   */
  const handleResendOTP = async (setFieldError) => {
    
    setSuccessMessage({
      isSuccess: false,
      message: '',
    });
    const requestData = {
      MobileNo: mobileNumber,
    };
    try {
      toggleLoader();
      const response = await sendOtp(requestData);

      // Handle the response data if needed
      if (response && response?.status === 200) {
        console.log(response?.data?.Message, 'ressss');
        if (currentLanguage && response?.data?.Message) {
          const message = JSON.parse(response?.data?.Message);
          const messageTranslation = message[currentLanguage];
          setSuccessMessage({
            isSuccess: true,
            message: messageTranslation,
          });
          window.dataLayer.push({ event: 'bdotpresendbtn' });
          startTimer()
        }
      }
    } catch (error) {}
    setTimeout(() => {
      toggleLoader();
    }, 500); // Add a small delay before hiding the loader (500ms in this example)
  };

  const handleOTPKeyDown = (event) => {
    const keyPressed = event.key;
    const currentValue = event.target.value;

    // Allow only alphanumeric characters before the 6th digit
    if (currentValue.length < 6 && !/^[a-zA-Z]+$/.test(keyPressed)) {
      event.preventDefault();
    }

    // Allow backspace and delete keys if there are characters in the input field
    if (
      (keyPressed === 'Backspace' || keyPressed === 'Delete') &&
      currentValue.length > 0
    ) {
      return;
    }

    // Prevent further input after the 6th digit
    if (
      currentValue.length >= 6 &&
      keyPressed !== 'Backspace' &&
      keyPressed !== 'Delete'
    ) {
      event.preventDefault();
    }
  };

  const handleChange = (e) => {
    setSuccessMessage({
      isSuccess: false,
      message: '',
    });
  };

  return (
    <>
      <div className="position-relative modal-otp">
        {/* <div className="backArrow position-absolute">
          <img
            src="assets/images/edit-icon.png"
            style={{
              cursor: 'pointer',
              width: '20px',
            }}
            onClick={handleGoBack}
          />
        </div> */}

        <Formik
          validationSchema={OtpVerificationSchema}
          initialValues={{ OTP: '' }}
          onSubmit={handleSubmit}>
          {({
            handleSubmit,
            values,
            errors,
            handleChange: formikHandleChange,
          }) => (
            <Form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Form.Group controlId="OTP">
                <Form.Label className="mb-2 form-refer-label">
                  {' '}
                  {t.enter_otp}
                </Form.Label>
                <Field
                  className="form-input"
                  type="text"
                  name="OTP"
                  value={values.OTP}
                  onChange={(e) => {
                    handleChange(e);
                    formikHandleChange(e);
                  }}
                  onKeyDown={handleOTPKeyDown}
                />
                {successMessage &&
                  successMessage?.isSuccess !== true &&
                  errors.OTP && (
                    <div className="error_message">{errors.OTP}</div>
                  )}
                {successMessage?.isSuccess !== true && errors.Message && (
                  <div className="error_message">{errors.Message}</div>
                )}
                {successMessage && successMessage?.isSuccess == true && (
                  <div className="error_message">{successMessage?.message}</div>
                )}
              </Form.Group>

              <Button
                className={`mt-md-5 mt-3 mb-1 bdotpcontinuebtn`}
                variant="danger"
                type="submit"
                block>
                {t.submit}
              </Button>
              <div className="text-center text-underline mb-2">
                <Button
                 variant="link"
                  className={`resend-link bdotpresendbtn`}
                  onClick={handleResendOTP}
                  disabled={isButtonDisabled}
                  style={{fontSize:"15px"}}
                  >

                  {isButtonDisabled ? `Resend OTP in ${timer} seconds` : 'Resend OTP'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default OtpVerificationForm;
