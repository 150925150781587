import React from 'react';
import HeroBannerContest from './common/HeroBannerContest';
import WinnerTable from './common/WinnerTable';
import HowWinCard from './common/HowWinCard';
import useTranslation from '../utils/hooks/use-translation';

import MarqueeStrip from './common/MarqueeStrip';

export default function Contest() {
  const { t } = useTranslation();
  return (
    <>
      {/* Render the HeroBannerContest component */}
      <HeroBannerContest
        image="/assets/images/bottle-ball.png"
        alt="ball bottle"
        heading={t.grab_a_coke}
        subHeading={t.witness_the}
        paragraph={t.icc_wc}
        subParagraph={t.in_person}
      />

      {/* Render the WinTicketStrip component */}
      <MarqueeStrip />

      {/* Container with background pattern */}
      <div className="bg-pattern-6">
        {/* Render the HowWinCard component */}
        <HowWinCard />

        {/* Render the WinnerTable component */}
        <WinnerTable />
      </div>
    </>
  );
}
