import React, { useContext, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { sendClaimTicket } from '../../service';
import useAuth from '../../utils/hooks/use-auth';
import AppModal from './AppModal';
import TicketWinnerModal from './TicketWinnerModal';
import { claimTicketValidationSchema } from '../../utils/validations';
import {
  getCDSPixelId,
  handleErrorMessages,
  handleKeyPressName,
} from '../../utils/helper';
import { fetchWalletByUserId } from '../../redux/slice/walletSlice';
import { useDispatch } from 'react-redux';
import {
  CityOptions,
  privacy_policy,
  terms_condition,
} from '../../config/constant';
import { getGeoData } from '../../utils/cdsHelper';
import DateTimePicker from '../common/DatePicker';
import MultiSelectComponent from '../common/MultiSelectComponent';
import useTranslation from '../../utils/hooks/use-translation';
import { LoaderContext } from '../loader/LoaderContext';

const ClaimTicketFormModal = ({ t, handleClose, show = true,isFoodPandaTicketWin}) => {
  const dispatch = useDispatch();
  const { userDetails } = useAuth(); // Retrieve user details using the useAuth() hook

  const [claimSuccess, setClaimSuccess] = useState(false); // Initialize state for claim success status
  const [selDate, setSelDate] = React.useState();
  const { currentLanguge } = useTranslation();
  const { toggleLoader } = useContext(LoaderContext);
  
  /**
   * Handle form submission
   * @param {Object} values - Form values
   * @param {Function} resetForm - Formik's resetForm function
   * @param {Function} setFieldError - Formik's setFieldError function
   */

  // Calculate the minimum and maximum dates based on age constraints
  const minAge = 18;
  const maxAge = 100;

  // Calculate minimum and maximum birth dates
  const minBirthDate = new Date();
  minBirthDate.setFullYear(minBirthDate.getFullYear() - minAge);

  const maxBirthDate = new Date();
  maxBirthDate.setFullYear(maxBirthDate.getFullYear() - maxAge);
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    console.log(values, 'values');

    try {
      toggleLoader();
      const hfSessionKey = await getCDSPixelId();
      let geoData = getGeoData();
      const birthdate = new Date(values?.birthdate);
      const birthdateUTC = new Date(
        Date.UTC(
          birthdate.getFullYear(),
          birthdate.getMonth(),
          birthdate.getDate()
        )
      );
      const requestData = {
        UserId: userDetails?.Id,
        MobileNo: values.MobileNo,
        Name: values.Name,
        Email: values.Email,
        birthdate: birthdateUTC.toISOString(),
        Source:isFoodPandaTicketWin ? "FP" : "GC",
        HfSession: hfSessionKey,
        ...geoData,
      };
       

      const response = await sendClaimTicket(requestData); // Send a claim ticket request with the provided data
      if (response && response.status === 200) {
        resetForm(); // Reset the form using Formik's resetForm function
        setClaimSuccess(true); // Set the claim success status to true
        window.dataLayer.push({ event: 'bd_ticketwinnerclaim' });
        dispatch(fetchWalletByUserId());
      }
    } catch (error) {
      handleErrorMessages(currentLanguge, error, setFieldError);
    } finally {
      toggleLoader();
      setTimeout(() => {}, 500);
    }
  };

  /**
   * @method renderPartnershipForm
   * @description render partnership form fields
   */
  const renderClaimTicketForm = () => {
    return (
      <>
        <Formik
          validationSchema={claimTicketValidationSchema(t)}
          initialValues={{
            Name: '',
            MobileNo: userDetails && userDetails?.MobileNo,
            Email: "",
            completeAddress: "",
            State: "",
            City: "",
            Pincode: "",
            birthdate: "",
          }} // Initial values for form fields
          onSubmit={handleSubmit} // Submit handler function
        >
          {({ handleSubmit, errors }) => (
            <Form noValidate onSubmit={handleSubmit} autoComplete="off">
              {/* name field  */}
              <Form.Group controlId="name" className="mb-4">
                <Form.Label className="mb-2">
                  {t.full_name} ({t.govt_id})<span className="required">*</span>
                </Form.Label>
                <Field
                  type="text"
                  name="Name"
                  className={`form-control ${errors.Name ? 'is-invalid' : ''}`}
                  placeholder={t.enter_name}
                  onInput={handleKeyPressName}
                />
                <ErrorMessage
                  name="Name"
                  className="error_message"
                  component="div"
                />
              </Form.Group>

              {/* Mobile Number field */}
              <Form.Group controlId="mobileNumber" className="mb-4">
                <Form.Label className="mb-2">
                  {t.mobile_no_mandatory}
                </Form.Label>
                <Field
                  type="text"
                  name="MobileNo"
                  className={`form-control ${
                    errors.MobileNo ? 'is-invalid' : ''
                  }`}
                  placeholder={t.enter_mobile_number}
                  readOnly
                />
                <ErrorMessage
                  name="MobileNo"
                  className="error_message"
                  component="div"
                />
              </Form.Group>

              {/* email field  */}
              <Form.Group controlId="email" className="mb-4">
                <Form.Label className="mb-2">
                  {t.email} {t.mandatory} <span className="required">*</span>
                </Form.Label>
                <Field
                  type="email"
                  name="Email"
                  className={`form-control ${errors.Email ? 'is-invalid' : ''}`}
                  placeholder={t.enter_email}
                />
                <ErrorMessage
                  name="Email"
                  className="error_message"
                  component="div"
                />
              </Form.Group>

              {/* date of birth field  */}
              <Form.Group controlId="birthdate" className="mb-4">
                <Form.Label className="mb-2">
                  {t.DOB}
                  <span className="required">*</span>
                </Form.Label>
                <Field
                  name="birthdate"
                  component={DateTimePicker}
                  minDate={maxBirthDate}
                  maxDate={minBirthDate}
                  setSelDate={setSelDate}
                  selDate={selDate}
                />
                <ErrorMessage
                  name="birthdate"
                  className="error_message"
                  component="div"
                />
              </Form.Group>
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  className="mt-md-5 mb-md-12 mb-7 mt-12 "
                  variant="danger"
                  type="submit"
                  block>
                  {t.submit}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  };
  return claimSuccess ? (
    <TicketWinnerModal closePopup={handleClose} />
  ) : (
    <AppModal
      onHide={handleClose}
      maxWidth="md"
      bodyClassName="pt-md-4 pt-8 px-0"
      backdrop={isFoodPandaTicketWin? "static" : true}
      closeButton={isFoodPandaTicketWin ? false :true}
      >
      <Row className="justify-content-center mx-0 mt-2">
        {/* <Col xs={3} className="text-center text-md-left pt-1 px-0">
          <img
            className="modal-left-img"
            src="assets/images/modal-trophy.png"
            alt="modal image"
          />
        </Col> */}
        <Col xs={12} className=" text-left p0-0 pt-md-2 pt-1">
          <div>
            <h3 className="text-center mb-6">{t.details_claim}</h3>
            {renderClaimTicketForm()}
          </div>
        </Col>
      </Row>
      <div className="mb-6">
        <h4 className="text-center mb-1">{t.mandatory_fields}</h4>
        <div className="d-flex justify-content-center modal-sub-title">
          <div className="mr-2 border-right">
            <a
              href={terms_condition}
              target={'_blank'}
              className=" mr-2 form-link">
              {t.terms_condition}
            </a>
          </div>
          <div>
            <a href={privacy_policy} target={'_blank'} className="form-link">
              {t.privacy_policy}
            </a>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
export default ClaimTicketFormModal;
