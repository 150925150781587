import AxiosHelper from "../utils/axiosHelper";
import { API } from "../config/apiUrl";
import { apiErrors } from "../config/apiErrorHandler";
import { getCDSPixelId } from "../utils/helper";

/**
 * This method is responsible for sending the OTP by making an API call.
 * It receives the user data as a parameter and returns the API response.
 * If an error occurs, it displays a toast error message using the `apiErrors` function.
 */
const hfSessionKey = await getCDSPixelId();

export const sendOtp = async (userData) => {
return await AxiosHelper.post(API.getData, userData);
};

/**
 * @method verifyOtp
 * @description verify otp
 */
export const verifyOtp = async (userData) => {
  return await AxiosHelper.post(API.postMobileVerifyOtp, userData);
};

/**
 * @method RegisterUser
 * @description register user
 */
export const RegisterUser = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.registerData, userData);
    return response;
  } catch (error) {
    return error
    //throw new Error('User creation failed.');
  }
};

export const updateProfileName = async (userData) => {
  return await AxiosHelper.post(API.updateProfileName, userData);
};

export const updateProfile = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.updateProfile, userData);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const updateProfileEmail = async (userData) => {
  return await AxiosHelper.post(API.updateProfileEmail, userData);
};

export const updateProfileAge = async (userData) => {
  return await AxiosHelper.post(API.updateProfileAge, userData);
};
export const updateProfileGender = async (userData) => {
  return await AxiosHelper.post(API.updateProfileGender, userData);
};

export const updateProfileCity = async (userData) => {
  return await AxiosHelper.post(API.updateProfileCity, userData);
};

export const updateProfileState = async (userData) => {
  return await AxiosHelper.post(API.updateProfileState, userData);
};
export const updateProfileStateCity = async (userData) => {
  return await AxiosHelper.post(API.updateProfileStateCity, userData);
};

export const updateProfilePinCode = async (userData) => {
  return await AxiosHelper.post(API.updateProfilePinCode, userData);
};

/**
 * @method validateAndRedeemCode
 * @description validate code
 */
export const validateAndRedeemCode = async (data) => {
  try {
    const response = await AxiosHelper.post(API.validateUniqueCode, data);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const validateAndRedeemCodevalue = async (data, callback) => {
  try {
    const validateAndRedeemMixData = {
      ...data,
      HfSession: hfSessionKey || "",
    }
    const response = await AxiosHelper.post(API.validateUniqueCode, validateAndRedeemMixData);
    return response;
  } catch (error) {
    console.log(error,"error api")
    callback(error);
  }
};

export const FoodPandaVerifyAndRedeemMixCode   = async (data, callback) => {
  try {
    const FoodPandaVerifyAndRedeemMixCode   = {
      ...data,
      HfSession: hfSessionKey || "",
    }
    const response = await AxiosHelper.post(API.FoodPandaVerifyAndRedeemMixCode  , FoodPandaVerifyAndRedeemMixCode  );
    return response;
  } catch (error) {
    console.log(error,"error api")
    callback(error);
  }
};

export const updateProfileImage = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.updateProfileImage, userData);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const voteNowForFlag = async (userData) => {
  try {
    const response = await AxiosHelper.post(API.voteNowForFlag, userData);
    return response;
  } catch (error) {
    // apiErrors(error)
    //throw new Error('User creation failed.');
  }
};
export const TossNowFlag = async (userData) => {
  const response = await AxiosHelper.post(API.tossNowForFlag, userData);
  return response;
};

export const getUserDetailsById = async (Id) => {
  try {
    const response = await AxiosHelper.post(API.getUserById, Id);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const getWalletDetailsById = async (Id) => {
  try {
    const response = await AxiosHelper.post(API.getWalletById, Id);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const getDetailsByIp = async (Id) => {
  try {
    const response = await AxiosHelper.get(API.getWalletById, Id);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};

export const getDetailsByMap = async (Id) => {
  try {
    const response = await AxiosHelper.get(API.getWalletById);
    return response;
  } catch (error) {
    apiErrors(error);
    //throw new Error('User creation failed.');
  }
};
/**
 * @method sendReferAFriendCode
 * @description send refer a friend code
 */
export const sendReferAFriendCode = async (userData) => {
  return await AxiosHelper.post(API.sendReferCode, userData);
};

/**
 * @method sendClaimMerch
 * @description send claim Merch
 */
export const sendClaimMerch = async (userData) => {
  return await AxiosHelper.post(API.sendClaimMerch, userData);
};

/**
 * @method sendClaimTicket
 * @description send claim Ticket
 */
export const sendClaimTicket = async (userData) => {
  console.log(userData,"userData")
 return await AxiosHelper.post(API.sendClaimTicket, userData);
};

/**
 * @method verifyReferCode
 * @description verify refer code
 */
export const verifyReferCode = async (userData) => {
  return await AxiosHelper.post(API.verifyreferCode, userData);
};

/**
 * @method getVotesForWorldCup
 * @description get votes for world cup
 */
export const getVotesForWorldCup = async () => {
  return await AxiosHelper.post(API.worldcupVotes);
};

/**
 * @method getLineGraph
 * @description get line graph
 */
export const getLineGraph = async () => {
  return await AxiosHelper.post(API.linegraphDetails);
};

/**
 * @method getLineGraph
 * @description get line graph
 */
export const checkmatchClaimedToday = async (data) => {
  return await AxiosHelper.post(API.checkMatchClaim, data);
};

/**
 * @method getTicketWinnerCount
 * @description get count of ticket winner  // need to replace with get
 */
export const getTicketWinnerCount = async () => {
  return await AxiosHelper.post(API.ticketWinnerCount, null);
};

export const getTicketWinnerUsers = async (data) => {
  return await AxiosHelper.post(API.getTicketWinnerUsers, data);
};

/**
* @method saveTicketWinner
* @description save ticket ones all ticket win    // need to replace with get
*/
export const saveTicketWinner = async (data) => {
  return await AxiosHelper.post(API.saveTicketWin, data);
};

export const DownloadTicketClaimDetails = async (data) =>{
  return await AxiosHelper.post(API.downloadticketClaimDetails,data)
}