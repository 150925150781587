/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/sass/components/thumbSlider.scss';
import useTranslation from '../../utils/hooks/use-translation';

function ThumbSlider() {
  const { t } = useTranslation();

  if (!t) {
    return <div>Loading translations...</div>;
  }

  var settings = {
    slidesToShow: 1,
    arrows: false,
    fade: true,
    autoplaySpeed: 2000,
    dots: true,
  };
  return (
    <Slider className="slides" {...settings}>
      <img
        className="slides-item"
        src="assets/images/slides/slide-4.jpg"
        alt="Coco cola"
      />
      <img
        className="slides-item"
        src="assets/images/slides/slide-2.jpg"
        alt="Coco cola"
      />
      <img
        className="slides-item"
        src="assets/images/slides/slide-3.jpg"
        alt="Coco cola"
      />
      <img
        className="slides-item"
        src="assets/images/slides/slide-1.jpg"
        alt="Coco cola"
      />
      <img
        className="slides-item"
        src="assets/images/slides/slide-5.jpg"
        alt="Coco cola"
      />
    </Slider>
  );
}

export default ThumbSlider;
