import React from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";

const daysFromRefDate = (date) =>
  date &&
  Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000);

const timeFilter = (time, minTime, maxTime, minDays, maxDays) => {
  const timeDays = daysFromRefDate(time);
  return (
    (timeDays !== minDays && timeDays !== maxDays) ||
    (timeDays === minDays && minTime < time.getTime()) ||
    (timeDays === maxDays && maxTime > time.getTime())
  );
};

const useTimeFilter = (minDate, maxDate) => {
  const minDays = React.useMemo(() => daysFromRefDate(minDate), [minDate]);
  const maxDays = React.useMemo(() => daysFromRefDate(maxDate), [maxDate]);

  return React.useCallback(
    (time) =>
      timeFilter(time, minDate.getTime(), maxDate.getTime(), minDays, maxDays),
    [minDate, maxDate, minDays, maxDays]
  );
};

const DateTimePicler = ({ minDate, maxDate, selDate, setSelDate }) => {
  // Fetch the required Formik properties
  const [field, meta] = useField("birthdate");
  const { setFieldValue } = useFormikContext();

  // ... (existing code)

  const handleChangeDate = (date) => {
    setSelDate(date);
    setFieldValue("birthdate", date); // Update the form field value with the selected date
  };

  const filterTime = useTimeFilter(minDate, maxDate);

  return (
    <>
     <DatePicker className="form-control"
        selected={selDate}
        onChange={handleChangeDate}
        minDate={minDate}
        maxDate={maxDate}
        filterTime={filterTime}
        isClearable
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100} // Set the number of years to show in the dropdown
        placeholderText="DD/MM/YYYY"
     
      />
    </>
  );
};

export default DateTimePicler;
