import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/sass/map.scss";
import "react-toastify/dist/ReactToastify.css";
import "./styles/sass/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import CustumRoutes from "./router/Routes";
import { LoaderProvider } from "../src/components/loader/LoaderContext";
import { API_PROD_URL, GOOGLE_CAPTCHA_KEY } from "./config/appConfig";
import Loader from "./components/loader";
import { setIpDetails } from "./redux/slice/commonSlice";
import {
  disableInspect,
  getClassByname,
  preventRightClick,
  shortcutKeyDisable,
} from "./utils/helper";

export default function App() {
  const { hash, location } = useLocation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state) => state?.common?.currentLanguage
  );
  const reCaptchaRef = useRef();
  const BASE_URL = window.location.origin;

  useEffect(() => {
    // Store the existing class names
    var bodyClassNames = document.body.className.split(" ");
    // Remove any existing language class
    bodyClassNames = bodyClassNames.filter(
      (className) => className !== "en" && className !== "bn"
    );
    // Add the current language class
    bodyClassNames.push(currentLanguage);
    // Check if 'modal-open' class exists
    const isModalOpen = bodyClassNames.includes("modal-open");
    // If modal is open, ensure 'modal-open' class is present
    if (isModalOpen) {
      bodyClassNames.push("modal-open");
    }

    // Update the body class names
    document.body.className = bodyClassNames.join(" ");

    // Log for debugging
    console.log(document.body.className, "Updated bodyClassName");
  }, [currentLanguage]);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const el = document.getElementById(hash?.replace("#", "")?.trim());
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }, 200);
    }
  }, [hash]);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        fetchData(ipAddress);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    const fetchData = async (ipAddress) => {
      try {
        const response = await axios.get(
          `https://pro.ip-api.com/json/${ipAddress}?key=pXmgwzTKeZ7sORl`
        );
        localStorage.setItem("ipDetails", JSON.stringify(response.data));
        dispatch(setIpDetails(response.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchIPAddress();
  }, [dispatch]);

  useEffect(() => {
    if (BASE_URL == API_PROD_URL) {
      preventRightClick();
      disableInspect();
    }
  }, []);

  return (
    <>
      <div className={getClassByname(currentLanguage)}>
        <LoaderProvider>
          {/* <Router basename="AWSBeanstalkHelloWorldWebApp_deploy"> */}
          {/* <Router basename='AWSBeanstalkHelloWorldWebApp_deploy/web'> */}

          <Header />
          <CustumRoutes />
          <Footer />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ReCAPTCHA
            //style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={reCaptchaRef}
            sitekey={GOOGLE_CAPTCHA_KEY}
          />

          <Loader />
        </LoaderProvider>
      </div>
    </>
  );
}
