import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppModal from './AppModal';
import { handleShare } from '../../utils/helper';
import { terms_condition } from '../../config/constant';
import useAuth from '../../utils/hooks/use-auth';
import { DownloadTicketClaimDetails } from '../../service';
import { Link } from 'react-router-dom';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import useTranslation from '../../utils/hooks/use-translation';

const TicketWinnerModal = ({ closePopup }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const { userDetails } = useAuth();
  const { t } = useTranslation();
  const walletDetails = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData
  );
  const totalTicketWins = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData?.Ticket
  );
  const enableDownloadButton = walletDetails?.TicketClaimed === 1;
  useEffect(() => {
    if (enableDownloadButton) {
      handleDownload();
    }
  }, [enableDownloadButton]);
  const handleDownload = () => {
    try {
      const data = {
        UserId: userDetails && userDetails.Id,
      };

      DownloadTicketClaimDetails(data)
        .then((response) => {
          if (response && response?.status === 200) {
            // window.open(response.data)
            setPdfUrl(response.data); // Assuming this sets the state correctly
            // downloadPdf(response.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching PDF URL:', error);
        });
    } catch (error) {
      console.error('Error in handleDownload:', error);
    }
  };

  return (
    <>
      {/* Modal Wrapper */}
      <AppModal maxWidth="md" bodyClassName=" pt-2 px-0" onHide={closePopup}>
        <div className="text-center ">
          {/* Modal Title */}
          <h3 className="mb-3">{t.thank_you}​</h3>

          {/* Delivery Message */}
          <p className="px-md-4  mb-1 within-custom">{t.within_days}</p>

          {/* Share Victory Title */}
          <h6 className="modal-title modal-text  mb-md-4 mb-2">
            {t.share_magical}
          </h6>

          <div className="modal-border">
            <a href="http://bd-icc23.coke2home.com">
              <img
                src="/assets/images/thanku-concert-ticket.png"
                alt=""
                className="modal-ticket"
              />
            </a>
          </div>

          {/* Share Button */}
          <div>
            <Link
              className="btn btn-danger btn-share  mt-2 mb-2 px-md-12 d-inline-block"
              onClick={() => {
                handleShare(
                  '',
                  t.social_share_2,
                  'https://elasticbeanstalk-017261-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/share-image.png',
                  'http://bd-icc23.coke2home.com'
                );
                ClickButtonCDP('Share_TicketWin');
              }}>
              {t.share}
            </Link>
          </div>
          <Link
            to={pdfUrl}
            target="_blank"
            className="d-inline-block text-center modal-link mb-md-2 mb-1">
            Click to Download Ticket 
          </Link>
        </div>
        {/* Terms & Conditions */}
        <div className="text-center">
          <Link
            className="d-inline-block modal-link mb-0"
            to={terms_condition}
            target="_blank">
            {t.term_condition_apply}
          </Link>
        </div>
      </AppModal>
    </>
  );
};

export default TicketWinnerModal;
