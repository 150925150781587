import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../utils/hooks/use-translation';
import { onLoadCDP } from '../../utils/cdsHelper';
import useAuth from '../../utils/hooks/use-auth';
import { isCampaignEnded } from '../../utils/helper';

const WinTicketModal = (props) => {
  // Destructuring props
  const { handleClose, closePopup, setTicketModel, handleClaimTicketModel,setCampaignEnded } =
    props;
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useAuth();
  const currentPathname = location.pathname;
  const { t } = useTranslation();
  useEffect(() => {
    onLoadCDP('Win_ICCTicket');
  }, []);
  const handleClaimNow = () => {
    if (isCampaignEnded()) {
      setCampaignEnded(true);
    } else {
      setTicketModel(true);
    }
    handleClose();
    closePopup();
    navigate(currentPathname, { replace: true });
  }
  
  return (
    <>
      <div className="text-center pl-4 pr-4">
        {/* Match Ticket Image */}
        <img
          src="assets/images/concert-ticket.png"
          alt="Match Ticket"
          className="img-fluid modal-img"
        />

        {/* Modal Title */}
        <h3 className="modal-title pb-4 ">
          {t.came}​<span className="d-block">{t.belive_won}​</span>
        </h3>

        {/* Congratulations Message */}
        <h4 className="mb-7">
          Congratulations! You have won a Coke Studio Bangla Live Concert
          Ticket!
        </h4>

        {/* Claim Now Button */}
        <a
          className="btn btn-danger d-block ml-md-12 mr-md-12 ml-5 mr-5 "
          onClick={handleClaimNow}>
          {t.claim_now}
        </a>
      </div>
    </>
  );
};

export default WinTicketModal;
