import { createContext, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

// Create a new context instance
const LoaderContext = createContext();

// Define the LoaderProvider component
const LoaderProvider = ({ children }) => {
  // Get the isLoading state from the Redux store using the useSelector hook
  const loading = useSelector((state) => state.common.isLoading);

  // Initialize the isLoading state and a function to update it
  const [isLoading, setIsLoading] = useState(false);

  // Update the isLoading state when the loading value changes
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  // Define a callback function to toggle the loader state
  const toggleLoader = useCallback((action) => {
    console.log(action,"action")
    setIsLoading((prevLoading) => (action ? action : !prevLoading));
  }, []);

  // Create the value object to be provided to consuming components
  const value = {
    isLoading, // The current loader state
    toggleLoader, // Function to toggle the loader state
  };

  // Render the LoaderContext.Provider with the value and children
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

// Export the LoaderProvider and LoaderContext for usage in other components
export { LoaderProvider, LoaderContext };
