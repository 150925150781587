// Import the `createSlice` function from `@reduxjs/toolkit`
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  isLoggedIn: false, // Indicates if the user is logged in or not
  userDetails: null, // Stores the details of the authenticated user
};

// Create the authentication slice using `createSlice`
export const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for handling successful login
    loginSuccess: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.isLoggedIn = !!payload; // Set isLoggedIn to true if payload is not empty
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for setting user details
    setUser: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for handling logout
    logout: (state, action) => {
      state.isLoggedIn = false; // Set isLoggedIn to false
      state.userDetails = null; // Reset user details to null
    },
  },
});

// Extract the action creators generated by `createSlice`
export const { loginSuccess, setUser, logout } = authSlice.actions;

// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
