import React, { useState } from 'react';

const CampaignEnded = () => {

  return (
    <>
      <div className="text-center pt-5">
        <img
              src="/assets/images/logo/logo.svg"
              alt="coke-logo"
              style={{ maxWidth: "100px" }}
        />
        <h3 className="modal-title pb-md-2 pb-5 pt-6">
        Sorry, Ticket Registration Has Ended.
        </h3>
      <h4 style={{fontWeight:"400"}}>We're all booked!</h4>  
      </div>
    </>
  );
};

export default CampaignEnded;
