import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ClaimTicketFormModal from "../../model/ClaimTicketFormModal";
import useAuth from "../../../utils/hooks/use-auth";
import useTranslation from "../../../utils/hooks/use-translation";
import AppModal from "../../model/AppModal";
import WinTicketModal from "../../model/WinTicketModal";
import TicketWinnerModal from "../../model/TicketWinnerModal";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DownloadTicketClaimDetails } from "../../../service";
import { isCampaignEnded } from "../../../utils/helper";

const MyWallet = ({
  handleClose,
  show,
  setShowLoginModal,
  isFoodPandaTicketWin,
  setCampaignEnded,
}) => {
  const { userDetails } = useAuth();
  const { t } = useTranslation();
  const [showClaimFormModal, setShowClaimFormModal] = useState(false);
  const [showClaimThankyou, setShowClaimThankyou] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;
  const walletDetails = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData
  );

  const totalSilverCoins = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData?.CoinDetail?.CTGoldCoin
  );

  const totalTicketWins = useSelector(
    (state) => state?.wallet?.walletDetails?.walletData?.Ticket
  );

  const enableClaimButton =
    totalTicketWins !== walletDetails?.TicketClaimed || isFoodPandaTicketWin;

  const [showEligibleToClaimModal, setShowEligibleToClaimModal] =
    useState(false);

  const enableDownloadButton = walletDetails?.TicketClaimed === 1;

  /**
   * @method userWinTicketModel
   * @description model will show ones user win the ticket
   */
  useEffect(() => {
    if (enableDownloadButton) {
      handleDownload();
    }
  }, [enableDownloadButton]);

  const handleClaimTicketModel = () => {
    if (userDetails) {
      setShowClaimThankyou(true);
    } else {
      setShowLoginModal();
    }
  };

  /**
   * @method closePopup
   * @description close model
   */
  const closePopup = () => {
    navigate(`${currentPathname}`, { replace: true });
  };
  const handleClaimTicket = () => {
    if (isCampaignEnded()) {
      handleClose();
      setCampaignEnded(true);
    } else if (enableClaimButton) {
      setShowClaimFormModal(true);
      handleClose();
    }
  };

  const handleDownload = () => {
    try {
      const data = {
        UserId: userDetails && userDetails.Id,
      };

      DownloadTicketClaimDetails(data)
        .then((response) => {
          if (response && response?.status === 200) {
            // window.open(response.data)
            setPdfUrl(response.data); // Assuming this sets the state correctly
            // downloadPdf(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching PDF URL:", error);
        });
    } catch (error) {
      console.error("Error in handleDownload:", error);
    }
  };
  return (
    <>
      {/* My Wins Modal */}
      <AppModal
        show={show}
        onHide={handleClose}
        bodyClassName=""
        modalClassName="modal-wallet"
      >
        <h2 className="display-1 text-center mb-0 mb-md-2">{t.my_wins}</h2>
        <Row>
          <Col md={12} className="text-center justify-content-center">
            <div>
              <img src="assets/images/concert-ticket.png" alt="Ticket" />{" "}
            </div>
          </Col>
        </Row>

        <h2 className="display-1 text-center">{t.coin_wallet}</h2>
        <Row className="">
          <Col className="text-center d-flex align-items-center justify-content-center flex-column px-0">
            <h4>{t.my_victory_coins}</h4>
            <div class="card ">
              <div class="card-body ">
                <img src="assets/images/Coke-Believe-Coin.png" alt="coin" />{" "}
              </div>
              <h4 class="card-title pt-md-3 pb-md-3 pt-1 ">
                {totalSilverCoins && totalSilverCoins ? totalSilverCoins : "0"}
                /5
              </h4>
            </div>
          </Col>
          <Col className="text-center d-flex align-items-center justify-content-center flex-column px-0">
            <h4 className="text-center">{t.my_match_coins}​</h4>
            <div class="card ">
              <div class="card-body">
                <img
                  src="assets/images/concert-ticket.png"
                  alt="Ticket"
                  class="wallet-ticket"
                />
              </div>
              <h4 class="card-title pt-md-3 pb-md-3 pt-1 ">
                {/* {totalGoldCoins && totalGoldCoins ? totalGoldCoins : "0"} */}
                {totalTicketWins && totalTicketWins ? totalTicketWins : "0"}/1
              </h4>
            </div>
          </Col>
        </Row>
        {enableClaimButton && (
          <button
            className={
              enableClaimButton
                ? "btn btn-secondary d-block mt-5 w-100"
                : "btn btn-light d-block mt-5  w-100 enableButton"
            }
            onClick={handleClaimTicket}
            disabled={!enableClaimButton}
          >
            {t.claim_your_world_cup}
          </button>
        )}
        <div className="mt-6 text-center">
          {walletDetails?.TicketClaimed > 0 && (
            <Link
              to={pdfUrl}
              target="_blank"
              className="d-block h5 mb-md-2 mb-1 claim-link"
            >
              Click to Download Ticket
            </Link>
          )}
        </div>
      </AppModal>

      {/* Eligible to Claim Modal */}
      {showEligibleToClaimModal && (
        <AppModal
          onHide={closePopup}
          maxWidth="md"
          bodyClassName="pt-md-4 pt-6 px-0 pb-8"
        >
          <WinTicketModal
            handleClose={handleClose}
            closePopup={closePopup}
            handleClaimTicketModel={handleClaimTicketModel}
            setTicketModel={setShowClaimFormModal}
          />
        </AppModal>
      )}

      {/* Claim Form Modal */}
      {showClaimFormModal && (
        <ClaimTicketFormModal
          show={showClaimFormModal}
          handleClose={() => setShowClaimFormModal(false)}
          t={t}
        />
      )}

      {/* Claim Thank You Modal */}
      {showClaimThankyou && <TicketWinnerModal closePopup={closePopup} />}
    </>
  );
};

export default MyWallet;
