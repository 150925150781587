import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import { validateAndRedeemCodevalue } from "../../service";
import AppModal from "../model/AppModal";
import ClaimTicketFormModal from "../model/ClaimTicketFormModal";
import useAuth from "../../utils/hooks/use-auth";
import useTranslation from "../../utils/hooks/use-translation";
import SilverVictoryCoin from "../model/SilverVictoryCoin";
import GoldCoinVictory from "../model/GoldCoinVictory";
import MyWallet from "./my-wallet/MyWallet";
import { handleInputLimitedLength, isCampaignEnded } from "../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";

const UniqueCode = (props) => {
  const {
    setShowLoginModal,
    getWalletDetails,
    setVictoryCoin,
    victoryCoin,
    codeRedeemModel,
    saveTicketWinnerApi,
    walletDetails,
    setAlreadyWonTheTicket,
    setCampaignEnded,
  } = props;
  const { t } = useTranslation();
  const { userDetails } = useAuth();

  const [popup, setPopupInfo] = useState({
    title: "",
    content: "",
  });

  const [showClaimTicket, setShowClaimTicket] = useState(false);
  const [showWallet, setShowMyWallet] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;
  const initialValues = {
    Code: "",
  };

  const inCorrectCodeModel = () => {
    //set the state to show this popup
    setPopupInfo({
      title: t.stumped,
      content: t.valid_code,
    });
  };

  const usedCodeModel = () => {
    setPopupInfo({
      title: t.stumped,
      content: t.already_userd_new,
    });
  };
  console.log(isCampaignEnded(), "isCampaignEnded");
  const handleSubmit = (values, { resetForm }) => {
    if (
      isCampaignEnded() &&
      userDetails &&
      walletDetails?.walletData?.TicketClaimed !== 1
    ) {
      setCampaignEnded(true);
    } else if (values.Code && userDetails) {
      if (walletDetails?.walletData?.TicketClaimed == 1) {
        setAlreadyWonTheTicket(true);
      } else {
        let reqData = {
          Code: values.Code,
          UserId: userDetails && userDetails.Id,
          MobileNo: userDetails && userDetails?.MobileNo,
        };
        const callback = (response) => {
          // Handle the API response or perform additional actions
          let code =
            response.response &&
            response.response.data &&
            response.response.data.ErrorCode;
          if (code === 404 || (code !== 200 && code !== 706)) {
            inCorrectCodeModel();
            resetForm();
          } else if (code === 706) {
            usedCodeModel();
            resetForm();
          }
        };
        validateAndRedeemCodevalue(reqData, callback)
          .then((response) => {
            // testing the popup conditionally
            console.log(response, "response");

            if (response.status === 200) {
              if (walletDetails?.TicketClaimed === 1) {
                setAlreadyWonTheTicket(true);
              } else if (
                response &&
                response?.data?.Data?.CTGoldCoin === 1 &&
                walletDetails?.TicketClaimed !== 1
              ) {
                saveTicketWinnerApi();
              }
              // ClickButtonCDP('Submit_Transaction_Code', cdpData);
              let data = response?.data?.Data;
              codeRedeemModel(data);
              getWalletDetails();
              resetForm();
              window.dataLayer.push({ event: "bd_uniquecode" });
            }
          })
          .catch((error) => {
            navigate(`${currentPathname}`, { replace: true });
            // Handle API errors
            console.error("API error:", error);
            // ...
          });
        setTimeout(() => {}, 500); // Add a small delay before hiding the loader (500ms in this example)
      }
    } else {
      resetForm();
      setShowLoginModal();
    }
  };

  const closePopup = () => {
    setVictoryCoin({
      title: "",
    });
    setPopupInfo({
      title: "", // Reset the title
      content: "", // Reset the content
    });
    navigate(`${currentPathname}`, { replace: true });
  };

  return (
    <div className="d-flex justify-content-end">
      {/* Unique Code Form */}
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, values }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="w-100"
            autoComplete="off"
          >
            {/* Input Field */}
            <Field name="inputField">
              {({ field }) => (
                <div className="tu-unique-code-wrapper">
                  <input
                    placeholder={`${t.unique_code_key}`}
                    {...field}
                    type="text"
                    name={"Code"}
                    value={values.Code}
                    onInput={(e) => {
                      handleInputLimitedLength(e, 9, 11);
                    }}
                  />
                  <button
                    className={`btn btn-sm btn-primary unicodesubmitbd`}
                    type="submit"
                  >
                    {`${t.submit}`}
                  </button>
                </div>
              )}
            </Field>
          </Form>
        )}
      </Formik>

      {/* Popup */}
      {popup.title && (
        <AppModal
          onHide={closePopup}
          maxWidth="md"
          headerClassName="mb-md-12 mb-3"
          bodyClassName="pt-md-2 pt-11 mt-1 px-0 pb-12 mb-md-4 mb-0"
        >
          <div className="text-center pt-12 pl-4 pr-4  pb-12 mt-12">
            <h2 className="display-1 pl-5 pr-5 pt-md-3 pt-1 mb-2 mb-md-4">
              {popup.title}
            </h2>
            <h4 className="mb-12 pb-md-2 px-3">{popup.content}</h4>
          </div>
        </AppModal>
      )}

      {/* Gold Victory Coin Modal */}
      {victoryCoin && victoryCoin?.title === "Gold" && (
        <AppModal
          onHide={closePopup}
          maxWidth="md"
          bodyClassName="pt-md-4 pt-10 px-0 pb-8"
        >
          <GoldCoinVictory
            setShowMyWallet={setShowMyWallet}
            setVictoryCoin={setVictoryCoin}
            closePopup={closePopup}
          />
        </AppModal>
      )}

      {/* Silver Victory Coin Modal */}
      {victoryCoin && victoryCoin?.title == "Silver" && (
        <AppModal
          onHide={closePopup}
          maxWidth="md"
          bodyClassName="pt-md-4 pt-10 px-0 pb-8"
        >
          <SilverVictoryCoin
            setShowMyWallet={setShowMyWallet}
            setVictoryCoin={setVictoryCoin}
            closePopup={closePopup}
          />
        </AppModal>
      )}

      {/* Claim Ticket Form Modal */}
      {showClaimTicket && (
        <ClaimTicketFormModal
          show={showClaimTicket}
          handleClose={() => setShowClaimTicket(false)}
          t={t}
        />
      )}

      {/* my wallet form modal  */}
      {showWallet && (
        <MyWallet
          t={t}
          show={showWallet}
          handleClose={() => setShowMyWallet(false)}
        />
      )}
    </div>
  );
};

export default UniqueCode;
