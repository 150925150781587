import React from "react";

function DisplayField({ name = "", value = "", setEditing, hideEdit = false }) {
  // Function to handle click event when the edit button is clicked
  const handleClick = () => {
    if (name === "City" || name === "State") {
      // If the field is City or State, add both "City" and "State" to the editing state array
      setEditing((p) => [...new Set([...p, "City", "State"])]);
    } else {
      // For other fields, add the field name to the editing state array
      setEditing((p) => [...new Set([...p, name])]);
    }
  };

  return (
    <div className="d-flex align-items-baseline flex-1 w-100">
      <h6 className="w-100 mb-0">{value}</h6> {/* Display the value */}
      {!hideEdit && (
        // Render the edit button if hideEdit is false
        <button type="button" className="tu-edit-btn" onClick={handleClick}>
          <img src="assets/images/edit-icon.png" alt="edit icon" />
        </button>
      )}
    </div>
  );
}

export default DisplayField;
