import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { getTicketWinnerCount, getTicketWinnerUsers } from '../../service';
import useTranslation from '../../utils/hooks/use-translation';

export default function WinnerTable() {
  const { t } = useTranslation();
  const [ticketWinnerList, setTicketWinnerList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10); // Default page size, can be modified as needed
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalTicketDetails, setTotalTicketDetails] = useState('');
  useEffect(() => {
    getTicketWinnerCountDetails();
  }, []);

  /**
   * @method getTicketWinnerCountDetails
   * @description get ticket count details
   */
  const getTicketWinnerCountDetails = async () => {
    try {
      const response = await getTicketWinnerCount();
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setTotalTicketDetails(data);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  };

  const fetchTicketWinnerList = useCallback(async () => {
    try {
      const res = await getTicketWinnerUsers({
        PageNo: pageNo,
        PageSize: pageSize,
      });
      if (res.status === 200) {
        const data = res.data?.Data;
        const WinnerList = data?.WinnerList;
        const TotalRow = data?.TotalRow;

        if (WinnerList?.length) {
          setTicketWinnerList(WinnerList);
          setTotalRecords(TotalRow);
        }
      }
    } catch (err) {
      // Handle error
    }
  }, [pageNo, pageSize]);

  useEffect(() => {
    fetchTicketWinnerList();
  }, [fetchTicketWinnerList]);

  const handlePrevious = () => {
    if (pageNo > 1) {
      setPageNo((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalRecords / pageSize);
    if (pageNo < maxPage) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };

  return (
    <section className="section-table winner-contest" id="power_players">
      <header className="section-header ">
        <h2 className="mb-md-4 mb-1">{t.power_players}</h2>
        <h5 className="section-desc px-6 px-md-0">
          {totalTicketDetails !== 0 &&
            totalTicketDetails > 200 &&
            t.already_won}
          <br className="d-none d-sm-inline-block" /> {t.collecting_coins}
        </h5>
      </header>
      <div className="container-fluid">
        <div className="row justify-content-center pt-md-4 pb-md-10 pb-2 ">
          <div className="col-8 winners-div">
            <div className="list-wrapper">
              {/* Winner list */}
              <ul className={`list list-contest`}>
                {/* List header */}
                <li className="list-item bg-dark">
                  <h6 className="list-item-1">{t.serial_no}</h6>
                  <h6 className="list-item-2">{t.avatar}</h6>
                  <h6 className="list-item-3 text-center">{t.name}</h6>
                  <h6 className="list-item-4 pl-md-0 pl-3">{t.phone_number}</h6>
                </li>

                {/* List items */}
                {ticketWinnerList?.map((user, index) => (
                  <li
                    className={
                      index % 2 === 0
                        ? 'list-item bg-danger'
                        : 'list-item bg-danger'
                    }>
                    <h6 className="list-item-1">
                      {(pageNo - 1) * pageSize + index + 1}.
                    </h6>
                    <div className="list-avatar-wrapper list-item-2">
                      {/* User avatar */}
                      <img
                        className="list-avatar"
                        src={
                          user.AvtarUrl
                            ? user.AvtarUrl
                            : 'assets/images/avatar.png'
                        }
                        alt="avatar"
                      />
                    </div>
                    <h6 className="mx-3  text-center">
                      {user.Name ? user.Name : 'N/A'}
                    </h6>
                    <h6 className="list-item-4">
                      ......
                      {user.PhoneNumber ? user.PhoneNumber.substring(6) : 'N/A'}
                    </h6>
                  </li>
                ))}
              </ul>

              {/* Pagination */}
              <div className="d-flex justify-content-center">
                <Button
                  variant="link"
                  size="sm"
                  onClick={handlePrevious}
                  className={`btn btn-sm btn-danger mr-5 paginationButton`}
                  disabled={pageNo === 1}>
                  {t.prev}
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  onClick={handleNext}
                  disabled={pageNo === Math.ceil(totalRecords / pageSize)}
                  className={`btn btn-sm btn-danger  paginationButton`}>
                  {t.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
