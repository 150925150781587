import en from '../lang/en.json'; // English
import bn from '../lang/bn.json'; // Bangoli
import { currentYear } from '../utils/helper'

const translations = {
  en,
  bn,
};

// Iterate over each JSON file in the translations object
for (const language in translations) {
  if (Object.hasOwnProperty.call(translations, language)) {
    const jsonString = JSON.stringify(translations[language]);
    const modifiedJsonString = jsonString.replace(/2023/g, currentYear);
    const modifiedJson = JSON.parse(modifiedJsonString);
    translations[language] = modifiedJson;
  }
}

export default translations;