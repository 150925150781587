/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/sass/components/heroBanner.scss';
import '../../styles/sass/components/slider.scss';
import { handleShare } from '../../utils/helper';
import useTranslation from '../../utils/hooks/use-translation';
import { ClickButtonCDP } from '../../utils/cdsHelper';

function HomeCarousel() {
  const { t } = useTranslation();

  if (!t) {
    return <div>Loading translations...</div>;
  }

  /**
   * @method getTicketWinnerCountDetails
   * @description get ticket count details
   */

  var settings = {
    slidesToShow: 1,
    arrows: false,
    fade: true,
    autoplaySpeed: 2000,
    dots: true,
  };
  console.log(t?.social_share, 't.social_share');
  return (
    <section className="section pt-0 position-relative">
      <a
        href="#"
        className="btn btn-primary btn-share"
        onClick={() => {
          handleShare(
            '',
            "Just grab a bottle of Coca-Cola & stand a chance to win Coke Studio Concert Tickets* T&C Apply.",
            'https://elasticbeanstalk-017261-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/pop.png',
            'http://bd-icc23.coke2home.com'
          );
          ClickButtonCDP('Page_Share');
        }}>
        <img src="assets/images/share-icon.svg" alt="share icon" />
      </a>
      <div className="hero-banner">
        <div className="hero-inner">
          <img
            className="hero-banner-frame d-none w-100 d-md-inline-block"
            src="assets/images/hero-banner/banner-frame.png"
            alt="banner frame"
          />
          <img
            className="hero-banner-frame d-inline-block w-100 d-md-none"
            src="assets/images/hero-banner/banner-frame-mobile.png"
            alt="banner frame"
          />

          <Slider className="hero-slider" {...settings}>
            <div className="hero-slide">
              <img
                className="w-100 d-none d-md-block banner-img"
                src="assets/images/hero-banner/banner-slide-1.png"
                alt="Coco cola"
              />
              <img
                className="w-100 d-block d-md-none banner-img"
                src="assets/images/hero-banner/banner-slide-m-1.png"
                alt="Coco cola"
              />
            </div>
            <div className="hero-slide">
              <img
                className="w-100 d-none d-md-block banner-img"
                src="assets/images/hero-banner/banner-slide-2.png"
                alt="Coco cola"
              />
              <img
                className="w-100 d-block d-md-none banner-img"
                src="assets/images/hero-banner/banner-slide-m-2.png"
                alt="Coco cola"
              />
            </div>
            <div className="hero-slide">
              <img
                className="w-100 d-none d-md-block banner-img"
                src="assets/images/hero-banner/banner-slide-3.png"
                alt="Coco cola"
              />
              <img
                className="w-100 d-block d-md-none banner-img"
                src="assets/images/hero-banner/banner-slide-m-3.png"
                alt="Coco cola"
              />
              {/* <UnlockPath /> */}
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default HomeCarousel;
