import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import AppModal from "../model/AppModal";
import {
  FoodPandaVerifyAndRedeemMixCode,
  validateAndRedeemCodevalue,
} from "../../service";
import { handleInputLimitedLength, isCampaignEnded, isFoodPandaEnded } from "../../utils/helper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const UniqueCodeForm = (props) => {
  const {
    show,
    t,
    onHide,
    getWalletDetails,
    handleShowLoginModal,
    codeRedeemModel,
    currentLanguage,
    saveTicketWinnerApi,
    setUniqueCodeModal,
    walletDetails,
    setAlreadyWonTheTicket,
    setCampaignEnded
  } = props;
  const [uniqueCodeError, setUniqueCodeError] = useState("");
  const location = useLocation();
  const serchQRPathName = location?.search;
  const user_details = useSelector((state) => state?.auth?.userDetails);
  console.log(serchQRPathName, "currentPathname");
  const minAndMaxLengthForUniqueCode = location.search == "?qr=true" ? 11 : 10;
  const UniqueCodeSchema = Yup.object().shape({
    code: Yup.string()
      .required(t.validation_messages.required)
      .min(
        minAndMaxLengthForUniqueCode,
        `${t.validation_messages.min_length} ${minAndMaxLengthForUniqueCode}`
      )
      .max(
        minAndMaxLengthForUniqueCode,
        `${t.validation_messages.min_length} ${minAndMaxLengthForUniqueCode}`
      ), // Use the correct error message key here
  });
  console.log(walletDetails, "walletDetails");
  const handleSubmit = (values, { resetForm }) => {
    if (values?.code && user_details) {
      console.log(isCampaignEnded(),"jouney",isFoodPandaEnded())
      if (walletDetails?.walletData?.TicketClaimed !== 1) {
        // Check the location.search condition to determine if it's before or after 12 AM on Nov 
        if (location.search === "?Ref=foodpanda" && isFoodPandaEnded() || location.search === "?qr=true" && isCampaignEnded()) {
          // It's before 12 AM on Nov 8 and location is not "?Ref=foodpanda", so don't make the API call
          setCampaignEnded(true);
          setUniqueCodeModal(false);
        } else {
          // It's after 12 AM on Nov 8 or location is "?Ref=foodpanda", make the API call
          let reqData = {
            Code: values.code,
            UserId: user_details && user_details.Id,
            MobileNo: user_details && user_details?.MobileNo,
          };
  
          const callback = (response) => {
            console.log(response,"ressssssss")
            // Handle the API response or perform additional actions
            let code = response.response && response.response.data?.Message;
            console.log(code,"codeerrrrr",response.response)
            const isJSON = /^(?:\{[\s\S]*\}|\[[\s\S]*\])$/;

            if (isJSON.test(code)) {
              const errorMessage = JSON.parse(code);
              console.log(
                currentLanguage,
                "currentLanguage",
                errorMessage[currentLanguage]
              );
              setUniqueCodeError(errorMessage[currentLanguage]);
            } else {
              setUniqueCodeError(code);
            }
          };
          const checkValidateAndRedeemCodevalue =
            location.search == "?Ref=foodpanda"
              ? FoodPandaVerifyAndRedeemMixCode
              : validateAndRedeemCodevalue;
  
          checkValidateAndRedeemCodevalue(reqData, callback)
            .then((response) => {
              console.log(response,"response")
              // testing the popup conditionally
              if (response && response?.status === 200) {
                console.log(response, "response");
  
                if (location.search == "?qr=true") {
                  if (response && response?.data?.Data?.CTGoldCoin === 1) {
                    saveTicketWinnerApi();
                  }
                } else if (location.search == "?Ref=foodpanda") {
                  if (response && response?.data?.Data?.FoodPanda === true) {
                    setUniqueCodeModal(false);
                  }
                }
                // ClickButtonCDP("Submit_Transaction_Code", cdpData);
                let data = response?.data?.Data;
                codeRedeemModel(data);
                getWalletDetails();
                resetForm();
                window.dataLayer.push({ event: "bd_uniquecode" });
              }
            })
            .catch((error) => {
              console.log(error, "errrrrrr");
              setTimeout(() => {}, 500); 
            });
          setTimeout(() => {}, 500); // Add a small delay before hiding the loader (500ms in this example)
        }
      } else if (walletDetails?.walletData?.TicketClaimed === 1) {
        setUniqueCodeModal(false);
        setAlreadyWonTheTicket(true);
      }
    } else {
      resetForm();
      onHide();
      handleShowLoginModal();
    }
  };
  return (
    <>
      <AppModal
        show={show}
        onHide={onHide}
        size="md"
        bodyClassName="pt-4 px-0"
        centered={true}
        closeButton={isFoodPandaEnded()}
        backdrop="static"
      >
        <Row className="justify-content-center mx-0">
          <Col md={12} className="text-center pt-md-4 pt-1">
            {/* <Modal.Title className="mb-2">
                {t.believe_you_can}
                <span className="d-block d-md-inline">{t.icc_tickets}</span>
              </Modal.Title> */}
            <div className="mb-4">
              <img
                src="/assets/images/logo/logo.svg"
                alt="coke-logo"
                style={{ maxWidth: "64px" }}
              />
              {location && location.search == "?Ref=foodpanda" && (
                <>
                  <img
                    className="mx-2"
                    src="/assets/images/icons/close.png"
                    alt="cross-icon"
                    style={{ maxWidth: "10px" }}
                  />
                  <img
                    src="assets/images/BuyNow/food-panda.png"
                    alt="foodpanda-logo"
                    style={{ maxWidth: "60px" }}
                  />
                </>
              )}
            </div>
            <Formik
              validationSchema={UniqueCodeSchema}
              initialValues={{ code: "" }}
              onSubmit={handleSubmit}
              //validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                  <Form.Group controlId="code">
                    <Form.Label className="mb-3">
                      {t.behind_cocacola}
                    </Form.Label>
                    <Field
                      type="text"
                      name="code"
                      placeHolder={t.enter_unique_code}
                      className={`form-control ${
                        errors.code ? "is-invalid" : ""
                      }`}
                      value={values.code}
                      onInput={(e) => {
                        handleInputLimitedLength(
                          e,
                          minAndMaxLengthForUniqueCode,
                          minAndMaxLengthForUniqueCode
                        );
                        setUniqueCodeError("");
                      }}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="code" component="div" />
                    {uniqueCodeError.length > 0 && (
                      <div className="error_message">{uniqueCodeError}</div>
                    )}
                  </Form.Group>
                  <Button
                    className={`mt-md-8 mt-7 unicodesubmitbd`}
                    variant="danger"
                    type="submit"
                    block
                  >
                    {t.submit}
                  </Button>
                  {/* <div className="text-center mt-5">
                    <a
                      className="text-underline modal-sub-title"
                      onClick={() => onHide()}
                    >
                      {t.skip}
                    </a>
                  </div> */}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        {/* <div className="">
          <h4 className="text-center modal-sub-title">
            {t.Preferred_langauage}
          </h4>
          <div className="d-flex justify-content-center modal-sub-title">
            <a
              href="#"
              className="mr-2"
              onClick={() => handleLanguageChange("bn")}
            >
              বাংলা
            </a>
            <a
              href="#"
              className="ml-2"
              onClick={() => handleLanguageChange("en")}
            >
              English
            </a>
          </div>
        </div> */}
      </AppModal>
    </>
  );
};
export default UniqueCodeForm;
