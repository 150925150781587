// Import the `combineReducers` function from "redux"
import { combineReducers } from "redux";

// Import the reducer from "commonSlice"
import commonSlice from "./commonSlice";
import wallet from "./walletSlice";

// Import the reducer from "./authenticationSlice"
import auth from "./authenticationSlice";

// Combine the reducers using `combineReducers`
export default combineReducers({
  common: commonSlice, // Assign the "commonSlice" reducer to the "common" key in the root state
  auth: auth, // Assign the "auth" reducer to the "auth" key in the root state
  wallet,
});
