import React, { useContext } from "react";
import { ErrorMessage, FastField, Field, Form, Formik } from "formik";
import { BsCheck } from "react-icons/bs";
import { LoaderContext } from "../../../loader/LoaderContext";
import useAuth from "../../../../utils/hooks/use-auth";

function FieldForm({
  type = "text",
  name = "",
  onSubmit,
  validationSchema,
  initialValues = {},
  api,
  getUserData,
  setEditing,
  isSelect = false,
  options = [],
  onInput
}) {
  const { userDetails } = useAuth();
  const { toggleLoader } = useContext(LoaderContext);

  const handleSubmit = async (values, actions) => {
    try {
      toggleLoader();
      const reqData = {
        ...values,
        Id: userDetails && userDetails.Id,
      };
      const res = await api(reqData);
      if (res.status === 200) {
        getUserData();
        setEditing((p) => p.filter((f) => f !== name));
      }
    } catch (err) {
      // Handle error if needed
    } finally {
      toggleLoader();
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className="d-flex align-items-center justify-content-between" autoComplete="off">
            <div>
              {isSelect ? (
                // Render select input field if isSelect prop is true
                <Field
                  as="select"
                  placeholder=""
                  name={name}
                  className="tu-user-input input-select edit"
                >
                  <option value="">Select</option>
                  {options?.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Field>
              ) : (
                // Render text input field by default
                <FastField
                  type={type}
                  placeholder=""
                  name={name}
                  onInput={onInput}
                  className="tu-user-input edit"
                />
              )}

              {/* Display error message for the field */}
              <ErrorMessage
                name={name}
                component="div"
                className="error_message tst"
              />
            </div>
            {/* Submit button */}
            <button type="submit" className="tu-save-btn">
              <BsCheck />
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FieldForm;
