import React, { useContext, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { sendOtp } from '../../service';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import {
  handleErrorMessages,
  handleKeyPressMobileNumber,
} from '../../utils/helper';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import useTranslation from '../../utils/hooks/use-translation';

const MobileNumberForm = (props) => {
  const { toggleLoader } = useContext(LoaderContext); // Access the toggleLoader function from the LoaderContext
  const { t, nextStep, setModelContent,startTimer } = props;
  const { currentLanguage } = useTranslation();
  /**
   * @method useEffect
   * @description React hook that sets the model content when the component mounts
   */
  useEffect(() => {
    setModelContent({
      title: t.welcome_player,
      // header: t.sign_up_redeem_coin,
      header: '',
      image: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @constant MobileNumberSchema
   * @description The form validation schema for mobile number input
   */
  const MobileNumberSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required(t.validation_messages.required)
      .matches(/^\d{11}$/, t.validation_messages.enter_valid_number)
      .min(11, `${t.validation_messages.min_length} 11`)
      .max(11, `${t.validation_messages.max_length} 11`),
  });

  /**
   * @method handleSubmit
   * @description Handles the form submission for sending mobileNumber and proceeding to the next step
   */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    toggleLoader(); // Show loader while sending OTP

    let data = {
      MobileNo: values.mobileNumber,
    };
    try {
      const response = await sendOtp(data);
      if (response.status === 200) {
        toggleLoader();
        resetForm();
        nextStep({
          mobile: values.mobileNumber,
        });
        startTimer()
      }
    } catch (error) {
      toggleLoader();
      handleErrorMessages(currentLanguage, error, setFieldError);
    }
  };

  return (
    <>
      <Formik
        validationSchema={MobileNumberSchema}
        initialValues={{ mobileNumber: '' }}
        onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Form.Group controlId="formBasicMobile">
              <Form.Label className="mb-2 form-refer-label">
                {t.enter_mobile_number}
              </Form.Label>
              <Field
                type="text"
                name="mobileNumber"
                className={`form-control ${
                  errors.mobileNumber ? 'is-invalid' : ''
                }`}
                value={values.mobileNumber}
                onChange={handleChange}
                onInput={handleKeyPressMobileNumber}
              />
              {errors?.mobileNumber && (
                <div className="error_message">{errors?.mobileNumber}</div>
              )}
              {errors?.MobileNo && (
                <div className="error_message">{errors?.MobileNo}</div>
              )}
            </Form.Group>
            <Button
              className={`mt-md-9 mt-7 mb-md-3 mb-2 `}
              variant="danger"
              type="submit"
              block>
              {t.continue}
            </Button>
            {errors.Message && (
              <div className="error_message">{errors.Message}</div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MobileNumberForm;
