import React, { useContext } from 'react';
import { PropagateLoader } from 'react-spinners';
import { LoaderContext } from './LoaderContext';

function Loader() {
  // Access the isLoading state from the LoaderContext
  const { isLoading } = useContext(LoaderContext);

  if (isLoading) {
    // Show the loader if isLoading is true
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          zIndex: 999999
        }}
      >
        <PropagateLoader color={'#f66f01'} width={'7px'} loading={true} />
      </div>
    );
  }

  // If isLoading is false, return null (no loader will be shown)
  return null;
}

export default React.memo(Loader);
