import React from 'react';

const AlreadyWonTheTicket = () => {

  return (
    <>
      <div className="text-center pt-5 pl-4 pr-4">
        <img
              src="/assets/images/logo/logo.svg"
              alt="coke-logo"
              style={{ maxWidth: "64px" }}
        />
        <h3 className="modal-title pb-md-5 pb-7 pl-5 pr-5 pt-6">
        You have already won a Coke Studio Bangla Concert Ticket
        </h3>
      </div>
    </>
  );
};

export default AlreadyWonTheTicket;
