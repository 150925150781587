import React from "react";
import { Row, Col } from "react-bootstrap";
import useTranslation from "../../utils/hooks/use-translation";

function PickupPointRules() {
  const {t} = useTranslation()
  return (
    <section className="section-rules" >
      <div className="container-fluid">
        {/* Heading */}
        <h2 className="text-center mt-md-12 mb-md-12 mb-4 mt-2">{t.before_reach}<br/> {t.keep_mind}</h2>
       <ol className="mb-0 ">
        <li className="pb-md-6">{t.need_registerd}</li>
        <li className="pb-md-6">{t.someone_else}</li>
        <li className="pb-md-6">{t.please_carry}</li>
        <li className="pb-md-6">{t.please_carry_2}</li>
        <li className="pb-md-6">{t.physical_ticket_process}</li>
       

       </ol>
      </div>
    </section>
  );
}

export default PickupPointRules;
