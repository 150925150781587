import { Link } from 'react-router-dom';
import useTranslation from '../../utils/hooks/use-translation';
import { ClickButtonCDP } from '../../utils/cdsHelper';

function BuyNow({ navigateTo }) {
  const { t } = useTranslation();
  const handlePandaShare = () => {
    navigateTo();
    ClickButtonCDP('BuyNow_foodpanda');
  };
  return (
    <div className="tu-sidebar-buy-main pl-md-7 pl-3">
      {/* Heading */}
      <h2 className="mb-3">{t.buy_now}</h2>

      {/* Buy Now items */}
      <div className="brand-items list-bottom-border">
        {/* First Buy Now item */}
        {/* <Link to={"https://chaldal.com/coca-cola"} onClick={() => navigateTo()} target={'_blank'}>
          <img src={"assets/images/BuyNow/buy-now-1.png"} alt="buy_now1 icon" />
        </Link> */}

        {/* Second Buy Now item */}
        <Link
          target="_blank"
          to={
            'https://urldefense.com/v3/__https:/bhpz.adj.st/?c=bd&s=s&vt=ds&banner=banner-e357f257-83a6-4fea-9017-b3f11f38f401&adj_t=zoo12og_r0d0uti&adj_campaign=other_other_mix_dma_ma_bd_display&adj_deeplink=foodpanda*3A*2F*2F*3Fc*3Dbd*26s*3Ds*26vt*3Dds*26banner*3Dbanner-e357f257-83a6-4fea-9017-b3f11f38f401&adj_redirect=https*3A*2F*2Fwww.foodpanda.com.bd*2F*3Futm_campaign*3Dother_other_web_dma_ma_bd_display*26utm_source*3D*26utm_medium*3Dother__;JSUlJSUlJSUlJSUlJSUlJSUlJSUl!!BiPlgCE7GXGj!y6KsTouAzI9Wj9-GlYFH0kpZhGl3Yr_GsDAFJl68PqoL2TrhpM2y4sGl3UVbiIaW4VvV9fp8KA6njrW2DGQbuVAIZ8PC$'
          }
          onClick={() => handlePandaShare()}
          className="buy-now-link">
          <img
            src={'assets/images/BuyNow/food-panda.png'}
            alt="buy_now2 icon"
          />
          {/* <span className="text-light ml-2">foodpanda</span> */}
        </Link>
      </div>
    </div>
  );
}

export default BuyNow;
