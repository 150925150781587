// Import the `createSlice` function from `@reduxjs/toolkit`
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the loading slice
const initialState = {
  isLoading: false, // Indicates if the application is currently in a loading state
  ipDetails: null, // Stores the IP details
  currentLanguage: "en",
};

// Create the loading slice using `createSlice`
const loadingSlice = createSlice({
  name: "common", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for toggling the loading state
    toggleLoading: (state, actions) => {
      console.log(state.isLoading,"loading")
      state.isLoading = actions?.payload ? actions.payload : !state.isLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    // Reducer function for setting IP details
    setIpDetails: (state, actions) => {
      state.ipDetails = actions.payload; // Set the IP details to the payload of the action
    },
    setLanguage: (state, actions) => {
      state.currentLanguage = actions.payload; // Set the IP details to the payload of the action
    },
  },
});

// Extract the action creators generated by `createSlice`
export const { toggleLoading, setIpDetails, setLanguage } =
  loadingSlice.actions;

// Export the reducer function generated by `createSlice`
export default loadingSlice.reducer;
