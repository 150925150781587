import React from "react";
import { Col, Row } from "react-bootstrap";

import useTranslation from "../../utils/hooks/use-translation";
import { privacy_policy, terms_condition } from "../../config/constant";

function MarqueeStrip() {
   const { t } = useTranslation();

   // Show loading message while translations are being loaded
   if (!t) {
      return <div>Loading translations...</div>;
   }

   return (
      <div className="marquee-wrapper">
         <div class="marquee">
            <div className="">
               <span className="d-flex justify-content-between">
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}

                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}</span>

            </div>
         </div>
         <div class="marquee strip-2">
            <div className="">
               <span className="d-flex justify-content-between"> {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}
                  <img src='/assets/images/win-trophy.png' alt='Trophy icon' className="" />
                  {t.win_tickets}</span>

            </div>
         </div>
      </div>
   );
}

export default MarqueeStrip;