import React from 'react';
import { Row, Col } from 'react-bootstrap';
import useTranslation from '../../utils/hooks/use-translation';

function HowWinCard() {
  const { t } = useTranslation();
  return (
    <section className="how-win-card pt-4 pt-md-8" id="how_to_win">
      <div className="container-fluid">
        {/* Heading */}
        <h2 className="text-center mb-md-8 mb-4">{t.how_to_win}*</h2>
        <Row className="flex-nowrap">
          <Col>
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className="card-img img-fluid mx-auto"
                    src="assets/images/win-coco-cola.png"
                    alt="coco cola"
                  />
                </div>
                <h5 className="card-title">{t.step_1}</h5>
                <p className="card-text">{t.step1_content}</p>
              </div>
            </div>
          </Col>

          {/* Card 2 */}
          <Col>
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className="card-img img-fluid mx-auto"
                    src="assets/images/bd-submit-star.png"
                    alt="submit star"
                  />
                  <a
                    href="#"
                    className="btn btn-danger btn-sm mt-1 mt-lg-2 mx-auto">
                    {t.submit}
                  </a>
                </div>

                <h5 className="card-title">{t.step_2}</h5>
                <p className="card-text">
                  {t.enter_the}
                  <span className="d-block">{t.unique_code}</span>{' '}
                </p>
              </div>
            </div>
          </Col>

          {/* Card 3 */}
          <Col>
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className="card-img img-fluid mx-auto"
                    src="assets/images/win-card-coins.png"
                    alt="coins"
                  />
                </div>
                <h5 className="card-title">{t.step_3}</h5>
                <p className="card-text">{t.htw_s3}</p>
              </div>
            </div>
          </Col>

          {/* Card 4 */}
          <Col>
            <div className="card">
              <div className="card-body text-center">
                <div className="card-img-wrapper">
                  {/* Card image */}
                  <img
                    className="card-img img-fluid mx-auto "
                    src="assets/images/win-card-ticket.png"
                    alt="ticket"
                  />
                </div>
                <h5 className="card-title">{t.step_4}</h5>
                <p className="card-text">{t.step4_content}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default HowWinCard;
