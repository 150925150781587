import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import {
  avatarImages,
  divisionOptions,
  privacy_policy,
  terms_condition,
} from '../../config/constant';
import {
  getCDSPixelId,
  getIpDetails,
  handleErrorMessages,
  handleKeyPressName,
  setUserDetails,
} from '../../utils/helper';
import { LoaderContext } from '../loader/LoaderContext'; // Import the LoaderContext
import { RegisterUser } from '../../service';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginSuccess } from '../../redux/slice/authenticationSlice';
import { ClickButtonCDP, getGeoData, onLoadCDP } from '../../utils/cdsHelper';
import useTranslation from '../../utils/hooks/use-translation';

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentLanguage } = useTranslation();
  const { toggleLoader } = useContext(LoaderContext);
  const { t, handleClose, mobileNumber, setModelContent } = props;
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [formValues, setFormValues] = useState({
    State: '', // Initialize other form values here
    Division: '',
    Label: '',
    Latitude: '',
    Longitude: '',
  });
  const { zip } = getIpDetails();
  /**
 
 Set initial state for modelContent.
 title: Title for the model content.
 header: Header for the model content.
 image: Image for the model content.
 */
  const modelContent = {
    title: t.you_are_almost_there,
    header: t.sign_up_playing_make_squad,
    image: '',
  };
  /**
  
  React hook that sets the model content when the component mounts.
  */
  useEffect(() => {
    setModelContent(modelContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
  
  Handles the selection of an avatar image.
  @param {string} imageUrl - The URL of the selected avatar image.
  @param {function} setFieldValue - Formik's setFieldValue function.
  */
  const handleAvatarSelection = (imageUrl, setFieldValue) => {
    setSelectedAvatar(imageUrl);
    setFieldValue('ProfileImg', imageUrl);
    setIsHovering(false);
  };
  /**
  
  Validation schema for the signup form.
  name: Validate the name field with an required format.
  agreeTerms: Validate that agreeTerms is set to true and require it to be set.
  */
  const SignupSchema = Yup.object().shape({
    agreeTerms: Yup.bool()
      .oneOf([true], t.validation_messages.terms_condition_required)
      .required(t.validation_messages.terms_condition_required),
    Name: Yup.string()
      .required(t.validation_messages.name_required)
      .min(5, t.validation_messages.min_name)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    Email: Yup.string()
      .email(t.validation_messages.invalid_email)
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/,
        t.validation_messages.invalid_email
      ), // Additional validation for "@" and ".",
    State: Yup.string().required(t.validation_messages.Division_required),
  });
  /**
  
  Handles the form submission.
  @param {object} values - Form values.
  @param {object} formikHelpers - Formik's form helper functions.
  */
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    const geoData = getGeoData();
    console.log(geoData, 'geoData');
    const hfSessionKey = await getCDSPixelId();
    const requestData = {
      MobileNo: mobileNumber,
      Name: values.Name ?? '',
      ProfileImg: selectedAvatar ? selectedAvatar : '',
      Email: values.Email,
      Latitude: formValues?.Latitude,
      Longitude: formValues?.Longitude,
      City: formValues?.Division,
      State: formValues?.Label,
      Pincode: zip && zip.length == 4 ? zip : '5555',
      HfSession: hfSessionKey,
      StateCode: geoData?.geo_state_province_code,
    };

    if (values) {
      RegisterUser(requestData).then((response) => {
        if (response?.status === 200) {
          const data = response.data?.Data;
          dispatch(loginSuccess(data));
          if (data) {
            resetForm();
            handleClose();
            setUserDetails(data);
            window.dataLayer.push({ event: 'bd_registrations' });
            console.log(geoData, 'geoData');
            // ClickButtonCDP("Submit_Reg", geoData);
            if (location.search == '?qr=true') {
              window.location.assign('?qr=true');
            } else if(location.search == "?Ref=foodpanda"){
              window.location.assign('?Ref=foodpanda');
            }else {
              window.location.assign('/');
            }
          } else {
            resetForm();
            handleClose();
            if (location.search == '?qr=true') {
              window.location.assign('?qr=true');
            } else if(location.search == "?Ref=foodpanda"){
              window.location.assign('?Ref=foodpanda');
            } else {
              window.location.assign('/');
            }
          }
          // setUserDetails(data);
        } else {
          handleErrorMessages(currentLanguage, response, setFieldError);
        }
      });
    }
  };
  /**
  
  Handles the mouse enter event.
  */
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  /**
  
  Renders the avatar options tooltip.
  @param {function} setFieldValue - Formik's setFieldValue function.
  @returns {JSX.Element} The Tooltip component with avatar options.
  */
  const renderTooltip = (setFieldValue) => (
    <Tooltip id="avatar-tooltip" placement="top-right">
      {avatarImages.map((imageUrl, index) => (
        <img
          key={index}
          width={100}
          height={100}
          src={imageUrl}
          alt={`Avatar ${index}`}
          className={`avatar-option ${
            selectedAvatar === imageUrl ? 'selected' : ''
          }`}
          onClick={() => handleAvatarSelection(imageUrl, setFieldValue)}
        />
      ))}
    </Tooltip>
  );

  return (
    <>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          Name: '',
          Email: '',
          mobileNumber: '',
          agreeTerms: true,
          ProfileImg: '',
        }}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form
            className="text-left mt-2"
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off">
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setIsHovering(false)}
              className="avtatBox mb-5"
              style={{
                display: 'inline-grid',
              }}>
              <OverlayTrigger
                overlay={() => renderTooltip(setFieldValue)}
                trigger="click">
                <label htmlFor="ProfileImg" style={{ cursor: 'pointer' }}>
                  <div className="signup-avtar" onClick={handleMouseEnter}>
                    {selectedAvatar ? (
                      <img
                        src={selectedAvatar}
                        alt="Selected Avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '50%',
                        }}
                      />
                    ) : (
                      <span>
                        {values.name && values.name.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>
                </label>
              </OverlayTrigger>
              {isHovering && (
                <div
                  className="avatar-dropdown"
                  style={{
                    position: 'absolute',
                    top: '150px',
                    left: '40px',
                    padding: '10px',
                    backgroundColor: '#f9f9f9',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    zIndex: 1,
                  }}>
                  {avatarImages.map((imageUrl, index) => (
                    <img
                      key={index}
                      width={100}
                      height={100}
                      src={imageUrl}
                      alt={`Avatar ${index}`}
                      className={`avatar-option ${
                        selectedAvatar === imageUrl ? 'selected' : ''
                      }`}
                      onClick={() => {
                        handleAvatarSelection(imageUrl, setFieldValue);
                      }}
                      style={{
                        cursor: 'pointer',
                        margin: '5px',
                        border: '2px solid transparent',
                        transition: 'border-color 0.3s ease',
                      }}
                    />
                  ))}
                </div>
              )}
              {errors.ProfileImg && <div>{errors.ProfileImg}</div>}
              <Form.Label className="mb-md-2 mb-1 mt-md-1">
                {t.choose_avatar} ({t.optional})
              </Form.Label>
            </div>
            <Form.Group controlId="Name">
              <Form.Label>
                {t.full_name} ({t.govt_id})
              </Form.Label>
              <Field
                type="text"
                name="Name"
                className={`form-control ${
                  errors.Name && touched.Name ? 'is-invalid' : ''
                }`}
                value={values.Name}
                onChange={handleChange}
                onInput={handleKeyPressName}
              />
              {errors.Name && (
                <div className="error_message">{errors.Name}</div>
              )}
            </Form.Group>
            {/* division field  */}
            <Form.Group controlId="Email" className="division select-wrapper">
              <Form.Label>{t.select_div} </Form.Label>
              <Field
                as="select"
                name="State"
                placeholder=""
                value={values.State}
                className="tu-user-input input-select edit d-block form-condition form-control"
                onChange={(e) => {
                  handleChange(e); // Call the handleChange function as usual
                  const selectedOption = divisionOptions.find(
                    (option) => option.value === e.target.value
                  );
                  if (selectedOption) {
                    // Extract the details you need from the selected option
                    const { value, label, latitude, longitude } =
                      selectedOption;

                    // Update the form values with the selected option details
                    setFormValues({
                      ...values,
                      Division: value,
                      Label: label,
                      Latitude: latitude,
                      Longitude: longitude,
                    });
                  }
                }}>
                <option value="">{t.select}</option>
                {divisionOptions?.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.value}
                  </option>
                ))}
              </Field>
              {errors.State && (
                <div className="error_message">{errors.State}</div>
              )}
            </Form.Group>

            {/* email address  */}
            <Form.Group controlId="Email">
              <Form.Label>
                {t.email_address} ({t.optional})
              </Form.Label>
              <Field
                type="email"
                name="Email"
                className={`form-control ${
                  errors.Email && touched.Email ? 'is-invalid' : ''
                }`}
                value={values.Email}
                onChange={handleChange}
              />
              {errors.Message && (
                <div className="error_message">{errors.Message}</div>
              )}
              {errors.Email && (
                <div className="error_message">{errors.Email}</div>
              )}
            </Form.Group>

            {/* agree terms  */}
            <Form.Group controlId="agreeTerms" className="pt-md-4 pt-1">
              <Form.Check
                className="form-condition"
                type="checkbox"
                name="agreeTerms"
                label={
                  <span>
                    {t.least_18}&nbsp;
                    <a
                      href={terms_condition}
                      className="text-underline"
                      target={'_blank'}>
                      {t.tc}
                    </a>{' '}
                    {t.and}&nbsp;
                    <a
                      className="text-underline"
                      href={privacy_policy}
                      target={'_blank'}>
                      {t.p_p}
                    </a>
                    .
                  </span>
                }
                checked={values.agreeTerms}
                onChange={handleChange}
                isInvalid={errors?.agreeTerms && touched.agreeTerms}
              />
              {errors?.agreeTerms && (
                <div className="error_message">{errors?.agreeTerms}</div>
              )}
            </Form.Group>

            <Button
              className={`mt-md-6 mt-4 bdsignupbtn`}
              variant="danger"
              type="submit"
              block>
              {t.sign_up}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
