import React, { useState } from 'react';
import useTranslation from '../../utils/hooks/use-translation';

// Define the SilverVictoryCoin component
const SilverVictoryCoin = ({ setShowMyWallet, setVictoryCoin, closePopup }) => {
  const handleSeeCollection = () => {
    setShowMyWallet(true);
    setVictoryCoin('');
    closePopup();
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center pt-5 pl-4 pr-4">
        {' '}
        {/* Container with text centered, top padding, and left/right padding */}
        <img
          src="assets/images/Believe-Coin-new.png" // Image source of a silver coin
          alt="Believe-Coin" // Alternate text for the image
          className="modal-coins" // CSS class applied to the image
        />
        <h3 className="modal-title pb-md-5 pb-7 pl-5 pr-5 pt-6">
          {' '}
          {/* Heading with CSS classes for styling */}
          {t.howzaaaat}​<span className="d-block">{t.win_belive_coin}​</span>
        </h3>
        <a
          className="btn btn-danger d-block mt-md-7 ml-md-12 mr-md-12 mb-md-8 mb-4 mt-4 d-flex align-items-center justify-content-center" // Button with CSS classes for styling
          onClick={handleSeeCollection}>
          {t.see_collection}
        </a>
      </div>
    </>
  );
};

export default SilverVictoryCoin;
