import { toast } from 'react-toastify';


/**
 * @method apiErrors
 * @description handle api error 
 */
export const apiErrors = (res) => {
    const response = res ? res.response : undefined;
    // Adding un authorize condition after disscussion with kk
    if (response && response.data && response.status === 401) {
        if (response && response.data && response.data.error) {
            toast.error('You are not authorized');
            localStorage.clear()
            sessionStorage.clear();
            window.location.assign('/')
        } else {
            toast.error(response.data.Message);
        }
    } else if (response && response.status === 400) {
        toast.error(response.data.Message);
    }else if (response && response.status === 409) {
        toast.error(response.data && response.data.Message)
    } else {
        toast.error('Something went wrong please try again later');
    }
}