import React from 'react';

import useTranslation from '../../utils/hooks/use-translation';
import { privacy_policy, terms_condition } from '../../config/constant';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();

  // Show loading message while translations are being loaded
  if (!t) {
    return <div>Loading translations...</div>;
  }

  return (
    <footer class="footer">
      <div class="container-fluid">
        <div class="footer-left">
          <p className="mt-2">
            {/* <img src={'assets/images/icons/food-label.png'} alt="food label" /> */}
            &copy; {t.trade_mark_owner}
          </p>
          <p>
            &reg; {t.coco_cola}
            <br />
          </p>
          <p>
            <Link className="link" to={terms_condition} target={'_blank'}>
              *{t.terms_condition}
              {'  '}
            </Link>{' '}
            |{' '}
            <Link className="link" to={privacy_policy} target={'_blank'}>
              {t.privacy_policy}
            </Link>
          </p>
        </div>
        <div class="footer-right">
          <p>Connect with us</p>
          <ul class="social-list">
            <li>
              <Link
                to="https://www.facebook.com/CSBanglaOfficial?mibextid=LQQJ4d"
                target="_blank">
                <span class="icon icon-facebook"></span>
              </Link>
            </li>
            <li>
              <Link
                to="https://instagram.com/cokestudiobanglaofficial?igshid=MzRlODBiNWFlZA=="
                target="_blank">
                <span class="icon icon-instagram"></span>
              </Link>
            </li>
            {/* <li>
              <Link to="#">
                <span class="icon icon-twitter"></span>
              </Link>
            </li> */}
            <li>
              <Link
                to="https://youtube.com/@CokeStudioBangla?si=KCKJM1xxrd9z2ZvM"
                target="_blank">
                <span class="icon icon-youtube"></span>
              </Link>
            </li>
            {/* <li>
              <Link to="#">
                <span class="icon icon-whatsapp"></span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
