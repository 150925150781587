import React from "react";
import { useToastContainer } from "react-toastify";
import useTranslation from "../../utils/hooks/use-translation";

const NotEligibleModal = () => {
const {t} = useTranslation()
  return (
    <>
      <div className="text-center pb-12 pl-4 pr-4 mb-md-12">
        <h1 className="display-1  pb-md-12 pb-11">{t.sorry}</h1>
        <h4 className=" pb-md-3 pt-md-8 pt-6">
        {t.promotion_limited}
        </h4>
        {/* {clipboardCopied && <p>Referral Code copied to clipboard!</p>} */}
      </div>
    </>
  );
};

export default NotEligibleModal;
