import * as Yup from "yup";

export const profileFieldValidation = (t) => ({
  Name: Yup.object({
    Name: Yup.string()
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name)
      .required(t.validation_messages.required),
  }),

  Email: Yup.object({
    Email: Yup.string()
      .email(t.validation_messages.invalid_email)
      .required(t.validation_messages.required),
  }),

  Gender: Yup.object({
    Gender: Yup.string().required(t.validation_messages.required),
  }),

  State: Yup.object({
    State: Yup.string().required(t.validation_messages.required),
  }),

  Pincode: Yup.object({
    Pincode: Yup.string()
      .length(4, t.validation_messages.postal_codes)
      .required(t.validation_messages.postal_code_required),
  }),

  MobileNo: Yup.object({
    MobileNo: Yup.string()
      .length(11, t.validation_messages.enter_digit)
      .required(t.validation_messages.required),
  }),

  Age: Yup.object({
    Age: Yup.string()
      .test("age-test", t.validation_messages.age_cri, (value) => {
        if (isNaN(Number(value))) {
          return false;
        } else if (Number(value) === 0) {
          return false;
        } else if (Number(value) < 18) {
          return false;
        } else if (Number(value) > 100) {
          return false;
        }

        return true;
      })
      .required(t.validation_messages.required),
  }),

  ProfileImg: Yup.object({
    ProfileImg: Yup.string().required(t.validation_messages.profile_required),
  }),
});

export const claimTicketValidationSchema = (t) =>
  Yup.object().shape({
    Name: Yup.string()
      .required(t.validation_messages.name_required)
      .min(5, t.validation_messages.min_name)
      .matches(/^[a-zA-Z ]*$/, t.validation_messages.invalid_name),
    Email: Yup.string().required(t.validation_messages.required).email(t.validation_messages.enter_valid_email),
    birthdate: Yup.string().required(t.validation_messages.dob_required),
  });

export const referFriendValidationSchema = (t) =>
  Yup.object().shape({
    MobileNumber: Yup.string()
      .required(t.validation_messages.required)
      .matches(/^\d{11}$/, t.validation_messages.enter_valid_number)
      .min(11, `${t.validation_messages.min_length} 11`)
      .max(11, `${t.validation_messages.max_length} 11`),
    Email: Yup.string().email(t.validation_messages.enter_valid_email),
  });
