import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import StyleGuides from '../components/StyleGuide';
import Contest from '../components/Contest';
import BelievoMeter from '../components/BelievoMeter';
import LeaderBoard from '../components/LeaderBoard';
import HowToClaim from '../components/HowToClaim';

export default function Router() {
  return (
    <Suspense fallback={<div>{'...loading'}</div>}>
      <Routes>
        {/* Route for the home page */}
        <Route path="/" exact element={<Home />} />

        {/* Route for the style guide page */}
        {/* <Route path="/style" exact element={<StyleGuides />} /> */}

        {/* Route for the contest page */}
        <Route path="/contest" exact element={<Contest />} />

        {/* Route for the leaderboard page */}
        <Route path="/leaderboard" exact element={<LeaderBoard />} />

        {/* Route for the BelievoMeter page */}
        {/* <Route path="/believo-meter" exact element={<BelievoMeter />} /> */}

        {/* Route for the modal component page */}
        <Route path="/claim" exact element={<HowToClaim />} />
      </Routes>
    </Suspense>
  );
}
