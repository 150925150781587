import React, { useState, useEffect, useCallback } from "react";
import HomeCarousel from "./common/HomeCarousel";
import useTranslation from "../utils/hooks/use-translation";

import "../styles/sass/components/tables.scss";
import { getTicketWinnerCount, getTicketWinnerUsers } from "../service";
import { Button } from "react-bootstrap";

export default function LeaderBoard() {
  const { t } = useTranslation();
  const [ticketWinnerList, setTicketWinnerList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10); // Default page size, can be modified as needed
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalTicketDetails, setTotalTicketDetails] = useState("");

  useEffect(() => {
    getTicketWinnerCountDetails();
  }, []);

  /**
   * @method getTicketWinnerCountDetails
   * @description get ticket count details
   */
  const getTicketWinnerCountDetails = async () => {
    try {
      const response = await getTicketWinnerCount();
      console.log(response, "response");
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setTotalTicketDetails(data);
      }
    } catch (error) {
      console.error("Error updating profile name:", error);
      // Handle the error as needed
    }
  };
  const fetchTicketWinnerList = useCallback(async () => {
    try {
      const res = await getTicketWinnerUsers({
        PageNo: pageNo,
        PageSize: pageSize,
      });
      if (res.status === 200) {
        const data = res.data?.Data;
        const WinnerList = data?.WinnerList;
        const TotalRow = data?.TotalRow;

        if (WinnerList?.length) {
          setTicketWinnerList(WinnerList);
          setTotalRecords(TotalRow);
        }
      }
    } catch (err) {
      // Handle error
    }
  }, [pageNo, pageSize]);

  useEffect(() => {
    fetchTicketWinnerList();
  }, [fetchTicketWinnerList]);

  const handlePrevious = () => {
    if (pageNo > 1) {
      setPageNo((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalRecords / pageSize);
    if (pageNo < maxPage) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };
  console.log(totalTicketDetails, "totalTicketDetails");
  return (
    <>
      <main role="main" class="main">
        <div className="position-relative" style={{ zIndex: 2 }}>
          <HomeCarousel />
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-9">
              <section className="section  px-4 px-sm-0">
                <header className="section-header pt-5">
                  <h3 className="section-title mb-0">Concert Ticket Winners</h3>
                  <h5 className="section-desc px-6 px-md-0">
                    {/* {totalTicketDetails !== 0 &&
                      totalTicketDetails > 200 &&
                      t.already_won} */}
                    <br className="d-sm-inline-block" />{" "}
                    {/* {t.collecting_coins} */}
                    Keep collecting coins to be a part of this memorable night
                  </h5>
                </header>
              </section>
            </div>
          </div>
        </div>
        {ticketWinnerList &&
        Array?.isArray(ticketWinnerList) &&
        ticketWinnerList?.length ? (
          <>
            <div className="container-fluid mt-7">
              <div className="row justify-content-center">
                <div className="col-16 col-md-7">
                  <div className="table-wrapper">
                    <table className="table leader-board-table">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Avatar</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketWinnerList?.map((user, index) => (
                          <tr>
                            <td className="text-center">
                              {(pageNo - 1) * pageSize + index + 1}.
                            </td>
                            <td className="text-center">
                              <img
                                className="pic"
                                src={
                                  user.AvtarUrl
                                    ? user.AvtarUrl
                                    : "assets/images/avatar.png"
                                }
                                alt=""
                              />
                            </td>

                            <td> {user.Name ? user.Name : "N/A"}</td>
                            <td className="text-center">
                              {/* {" "}
            ......
            {user.PhoneNumber
              ? user.PhoneNumber.substring(6)
              : "N/A"} */}
                              {user.PhoneNumber}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center my-5 pb-14">
              <Button
                variant="link"
                size="sm"
                onClick={handlePrevious}
                className="btn btn-primary py-3 px-4 mr-5"
                disabled={pageNo === 1}
              >
                {t.prev}
              </Button>
              <Button
                variant="link"
                size="sm"
                onClick={handleNext}
                disabled={pageNo === Math.ceil(totalRecords / pageSize)}
                className="btn btn-primary py-3 px-4"
              >
                {t.next}
              </Button>
            </div>
          </>
        ) : (
          <div style={{ height: "300px" }}>
            <h2 className="text-center mt-14">No Data Found</h2>
          </div>
        )}
      </main>
    </>
  );
}
