import React, { useState, useEffect, useRef } from "react";
import { Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Feature from "./Feature";
import BuyNow from "./BuyNow";
import PrivacyPolicy from "./PrivacyPolicy";

const Sidebar = ({
  t,
  setShowLoginModal,
  setShowMyWallet,
  handleLanguageChange,
  currentLanguage,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    // Add event listener to handle clicks outside the navbar
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const navigateTo = (route) => {
    // Close the sidebar
    setIsExpanded(false);
    // Navigate to the specified route
    navigate(route);
  };

  return (
    <div className="sidebar-main">
      {/* Navbar */}
      <Navbar expand="xxl" expanded={isExpanded} ref={navbarRef}>
        {/* Navbar Toggle Button */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
          className="mr-4"
        />

        {/* Navbar Content */}
        <Navbar.Collapse id="basic-navbar-nav" className="offcanvas-menu">
          {/* Feature Component */}
          <Feature
            setShowLoginModal={setShowLoginModal}
            setShowMyWallet={setShowMyWallet}
            navigateTo={navigateTo}
            setIsExpanded={setIsExpanded}
          />

          {/* BuyNow Component */}
          <BuyNow navigateTo={navigateTo} />

          {/* PrivacyPolicy Component */}
          <PrivacyPolicy
            navigateTo={navigateTo}
            handleLanguageChange={handleLanguageChange}
            currentLanguage={currentLanguage}
          />
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Sidebar;
