export const CAPCHA_SECRET = process.env.REACT_APP_RECAPCHA_SECRET_KEY;
export const CDS_PIXEL_ID = process.env.REACT_APP_CDS_PIXEL_ID_PROD;

export const avatarImages = [
  'assets/images/avatar-1.png',
  'assets/images/avatar-2.png',
  'assets/images/avatar-3.png',
  'assets/images/avatar-4.png',
  'assets/images/avatar-5.png',
  'assets/images/avatar-6.png',
  'assets/images/avatar-7.png',
];

export const ELIGABLE_COUNTRY = ['Bangladesh', 'India'];

export const dummy_data = {
  Id: 'AEABV4d2XdUrLrD0Qo9/Dw==',
  Name: 'Test1',
  Email: 'test1@gmail.com',
  MobileNo: '9911083603',
  Age: 20,
  Gender: 'Male',
  City: 'Ind',
  State: 'MP',
  Pincode: 123456,
  VictoryCoin: 0,
  MatchCoin: 0,
  ProfileImg: 'test1.png',
  Points: 5,
  Referred: 0,
  AcceptReferred: 0,
  RefCode: 'CS4243',
  Rank: 1,
};

export const languageList = [
  { label: 'हिन्दी', value: 'hi' },
  { label: 'English', value: 'en' },
  { label: 'ଓଡ଼ିଆ', value: 'or' },
  { label: 'తెలుగు', value: 'tl' },
  { label: 'বাংলা', value: 'bn' },
  { label: 'ગુજરાતી', value: 'gg' },
  { label: 'ಕನ್ನಡ', value: 'kn' },
];

export const validateName = (value) => {
  let error;
  if (!value) {
    error = 'Name is required';
  } else if (value.length < 3) {
    error = 'Name must be at least 3 characters long';
  } else if (value.length > 10) {
    error = 'Name cannot exceed 10 characters';
    value = value.substring(0, 10) + '...';
  }
  return { error, value };
};

export const handleKeyPress = (event) => {
  const keyPressed = event.key;
  // Allow only alphanumeric characters
  if (!/^[a-zA-Z0-9]+$/.test(keyPressed)) {
    event.preventDefault();
  }
};

export const forbiddenKeywords = ['on-', '+']; // List of forbidden keywords

export const forbiddenCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;

//pdf view for privacy policy and terms and conditions
export const privacy_policy =
  'https://elasticbeanstalk-017261-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/Terms+of+Use-Bangladesh_ICC.pdf';
// export const terms_condition =
//   "https://elasticbeanstalk-017261-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/Coca-Cola+ICC+Promo+Campiagn+2023_Clean.pdf";

export const terms_condition =
  'https://elasticbeanstalk-017261-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/T%26C_CSB+Live+Concert+2.0+Promo+Campiagn.pdf';

// Define an array of ticket venue options
export const TicketVenueOptions = [
  {
    id: 1,
    label: 'Oct 24, Tue, BAN vs SA, Mumbai',
    value: 'Oct 24, Tue, BAN vs SA, Mumbai',
  },
  {
    id: 2,
    label: 'Oct 28, Sat, BAN vs NL, Kolkata',
    value: 'Oct 28, Sat, BAN vs NL, Kolkata',
  },
  {
    id: 3,
    label: 'Oct 31, Tue, BAN vs PAK, Kolkata',
    value: 'Oct 31, Tue, BAN vs PAK, Kolkata',
  },
  {
    id: 4,
    label: 'Nov 05, Sun, IND vs SA, Kolkata',
    value: 'Nov 05, Sun, IND vs SA, Kolkata',
  },
];

export const CityOptions = [
  {
    id: 7,
    label: 'Barisal',
    value: 'Barisal',
  },
  {
    id: 2,
    label: 'Chittagong',
    value: 'Chittagong',
  },
  {
    id: 1,
    label: 'Dhaka',
    value: 'Dhaka',
  },
  {
    id: 4,
    label: 'Khulna',
    value: 'Khulna',
  },
  {
    id: 8,
    label: 'Mymensingh',
    value: 'Mymensingh',
  },
  {
    id: 5,
    label: 'Rajshahi',
    value: 'Rajshahi',
  },
  {
    id: 6,
    label: 'Rangpur',
    value: 'Rangpur',
  },
  {
    id: 3,
    label: 'Sylhet',
    value: 'Sylhet',
  },
];

export const divisionOptions = [
  {
    id: 7,
    label: 'Barisal',
    value: 'Barisal',
    latitude: 22.701,
    longitude: 90.3535,
  },
  {
    id: 2,
    label: 'Chittagong',
    value: 'Chittagong',
    latitude: 22.3569,
    longitude: 91.7832,
  },
  {
    id: 1,
    label: 'Dhaka',
    value: 'Dhaka',
    latitude: 23.8103,
    longitude: 90.4125,
  },
  {
    id: 4,
    label: 'Khulna',
    value: 'Khulna',
    latitude: 22.8152,
    longitude: 89.5509,
  },
  {
    id: 8,
    label: 'Mymensingh',
    value: 'Mymensingh',
    latitude: 24.7575,
    longitude: 90.405,
  },
  {
    id: 5,
    label: 'Rajshahi',
    value: 'Rajshahi',
    latitude: 24.374,
    longitude: 88.6046,
  },
  {
    id: 6,
    label: 'Rangpur',
    value: 'Rangpur',
    latitude: 25.7439,
    longitude: 89.2752,
  },
  {
    id: 3,
    label: 'Sylhet',
    value: 'Sylhet',
    latitude: 24.8897,
    longitude: 91.8719,
  },
];
