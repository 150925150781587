import React, { useRef, useEffect } from "react";
import { MapContainer, ImageOverlay, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import LineChart from "./LineChart";
import { Container } from "react-bootstrap";
import { roundedValue } from "../utils/helper";
import { useHref } from "react-router-dom";
import useTranslation from "../utils/hooks/use-translation";

const customMarker = new L.Icon({
  iconUrl: "assets/images/marker.png", // URL of the marker image
  iconSize: [20, 20], // Size of the marker icon
  iconAnchor: [4, -20], // Anchor point of the icon relative to its top-left corner
  popupAnchor: [2, -31], // Anchor point of the popup relative to the marker's anchor
  // You can also define a shadow for the marker using shadowUrl, shadowSize, and shadowAnchor
});



// // Data for the markers on the map
const myData = [
  {
    id: 1,
    name: "Chittagong Ka Pulse",
    pulse: "95%",
    stopId: "STOPID-CTG 123",
    coordinates: [22.3569, 91.7832], // Chittagong coordinates
  },
  {
    id: 2,
    name: "Sylhet Ka Pulse",
    pulse: "85%",
    stopId: "STOPID-SYL 456",
    coordinates: [24.8949, 91.8687], // Sylhet coordinates
  },
  {
    id: 3,
    name: "Rajshahi Ka Pulse",
    pulse: "80%",
    stopId: "STOPID-RJS 789",
    coordinates: [24.374, 88.6011], // Rajshahi coordinates
  },
  {
    id: 4,
    name: "Khulna Ka Pulse",
    pulse: "70%",
    stopId: "STOPID-KHL 012",
    coordinates: [22.8158, 89.5682], // Khulna coordinates
  },
  {
    id: 4,
    name: "Dhaka believes Bangladesh will win",
    pulse: "70%",
    stopId: "Chhatak-KHL 012",
    coordinates: [25.0364, 91.6644], // Chhatak coordinates
  },
];

const MapComponent = ({ voteDetails }) => {
  // Define the bounds of the map to limit the viewable area
  const banglaBounds = L.latLngBounds(
    L.latLng(20.7467, 88.0089), // Southwest coordinates of Bangladesh
    L.latLng(26.631, 92.6733) // Northeast coordinates of Bangladesh
  );
  const mapRef = useRef(null);
  const {t} = useTranslation()
  const initialZoom = 7.61;
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      map.invalidateSize();
      // Additional code to handle map resizing or other operations
    }
  }, []);
  return (
    <MapContainer
      center={[23.685, 88.222]} // Center of Bangladesh
      zoom={initialZoom} // Increase the zoom level to make the map more visible
      zoomSnap={0.1}
      ref={mapRef}
      zoomDelta={0.1}
      className="scrollable-map"
      scrollWheelZoom={false} // Disable zooming via scroll wheel
      doubleClickZoom={false} // Disable zooming via double-click
      touchZoom={false} // Disable zooming via touch gestures
      zoomControl={false} // Disable the default zoom control
      dragging={false} // Disable dragging the map
      attributionControl={false}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: "#205098",
      }}
      // attributionControl={false}
    >
      {/* Add an image overlay to the map */}
      <div className="mapSection-title">
        <h4>
        {t.make_your_vote}
          <br />
          {t.nation_belief}
        </h4>
      </div>
      <ImageOverlay
        url={"assets/images/bangla-map.svg"} // URL of the map image
        bounds={banglaBounds} // Bounds of the map image
        interactive={false} // Disable interaction with the overlay
        zIndex={0} // Set the z-index of the overlay
      />

      {/* Iterate over the data and create markers for each item */}
      {voteDetails &&
        voteDetails.length !== 0 &&
        voteDetails?.map((stop) => {
          return (
            <Marker
              key={stop.id}
              position={[stop.Latitude, stop.Longitude]}
              icon={customMarker}
            >
              <Popup>
                <div className="map-popup">
                  <div className="popup-pulse">
                    <h3>{roundedValue(stop.Percentage)}%</h3>
                  </div>
                  <div className="popup-title">
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: `${stop.City} believes  <br />  Bangladesh will win`,
                      }}
                    ></h3>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        })}
    </MapContainer>
  );
};

export default function world_cup_ticket({ voteDetails }) {
  return (
    <div className="mapSection mapSectionContainer pt-md-6 pt-0 ">
      <Container>
        <div className="mapSection-left">
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="mapSection-container"
          >
            <MapComponent voteDetails={voteDetails} />
          </div>
        </div>
        {/* <div className="mapSection-right ">
          <LineChart />
        </div> */}
      </Container>
    </div>
  );
}
