/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import MobileNumberForm from '../form/MobileNumberForm';
import OtpVerificationForm from '../form/OtpVerificationForm';
import SignUpForm from '../form/SignUpForm';
import useTranslation from '../../utils/hooks/use-translation';
import AppModal from './AppModal';

const CommonModel = (props) => {
  const { t } = useTranslation();

  const { title, header, showLoginModal, handleClose, active } = props;

  const [show, setShow] = useState(showLoginModal);
  const [mobileNumber, setMobileNumber] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [activeStep, setActiveStep] = useState(active);
  const [timer, setTimer] = useState(30); // Initial timer value
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [modelContent, setModelContent] = useState({
    title: title, // Specify the title for the model content
    header: header, // Specify the header for the model content
    image: '', // Specify the image for the model content
  });

  //call immidiatly before render the componnnent, open model
  useEffect(() => {
    setShow(showLoginModal);
  }, [showLoginModal]);

  //handle next step
  const nextStep = ({ mobile, uniqueCode }) => {
    setActiveStep((prevStep) => prevStep + 1);
    setMobileNumber(mobile);
    setUniqueCode(uniqueCode);
  };

  //handle previous step
  const previousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  //handle model dynamic content change
  const handleModelContent = (content) => {
    setModelContent(content);
  };
  const startTimer = () => {
    setTimer(30); // Reset the timer to 30 seconds
    setIsButtonDisabled(true); // Disable the button
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  
    // When the timer reaches 0, enable the button and clear the interval
    setTimeout(() => {
      clearInterval(intervalId);
      setIsButtonDisabled(false); // Enable the button
    }, 30000); // 30 seconds
  };

  return (
    <>
      <AppModal onHide={handleClose} centered bodyClassName="pt-md-4 pt-6 px-0">
        <Row className="justify-content-center mx-0">
          {/* <Col xs={3} className="text-center text-md-left pt-1 px-0">
            <img
              className="modal-left-img"
              src={`${getBackgroundImage().sideImage}`}
              alt="modal image"
            />
          </Col> */}
          <Col
            xs={12}
            className={`p-0 pt-md-2 pt-1 ${
              activeStep === 3 ? 'text-left' : 'text-center'
            }`}>
            <div>
              <h3 className={`${activeStep === 4 ? 'mb-2' : 'mb-5'} `}>
                {modelContent && modelContent.title}
              </h3>
              <h4 className={`${activeStep === 4 ? 'mb-8' : ''} `}>
                {' '}
                {modelContent && modelContent.header}
              </h4>
              {activeStep === 2 && (
                <MobileNumberForm
                  nextStep={(activeStep) => nextStep(activeStep)}
                  setModelContent={(content) => handleModelContent(content)}
                  t={t}
                  style={{ minHeight: '430px' }}
                  className="m-number"
                  startTimer={startTimer}
                />
              )}
              {activeStep === 3 && (
                <OtpVerificationForm
                  nextStep={(activeStep) => nextStep(activeStep)}
                  previousStep={(activeStep) => previousStep(activeStep)}
                  setModelContent={(content) => handleModelContent(content)}
                  mobileNumber={mobileNumber}
                  t={t}
                  uniqueCode={uniqueCode}
                  redeemUniqueCode={(userInfo) => redeemUniqueCode(userInfo)}
                  startTimer={startTimer}
                  isButtonDisabled={isButtonDisabled}
                  timer={timer}
                />
              )}
              {activeStep === 4 && (
                <SignUpForm
                  nextStep={(activeStep) => nextStep(activeStep)}
                  setModelContent={(content) => handleModelContent(content)}
                  mobileNumber={mobileNumber}
                  t={t}
                  handleClose={handleClose}
                  uniqueCode={uniqueCode}
                  redeemUniqueCode={(userInfo) => redeemUniqueCode(userInfo)}
                />
              )}
            </div>
          </Col>
        </Row>
      </AppModal>
    </>
  );
};

export default CommonModel;
