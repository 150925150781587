import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../utils/hooks/use-auth";
import useTranslation from "../../utils/hooks/use-translation";
import {
  API_DEV_URL_FRONTEND,
  API_PROD_URL,
  COKE_BANGLA_DEV_URL,
  COKE_BANGLA_PROD_URL,
  REACT_LOCAL_BASE_URL,
} from "../../config/appConfig";

function Feature({ setShowLoginModal, setShowMyWallet, navigateTo }) {
  const { userDetails } = useAuth();
  const { t } = useTranslation();
  const [cokeStudioUrl, setCokeStudioUrl] = useState("");
  const APP_BASE_URL = window.location.origin;
  const userId = userDetails && userDetails?.Id;

  console.log(APP_BASE_URL, "APP_BASE_URL", userDetails);

  useEffect(() => {
    let FINAL_BASE_URL = "";
    if (APP_BASE_URL == API_DEV_URL_FRONTEND) {
      FINAL_BASE_URL = COKE_BANGLA_DEV_URL;
    } else if (APP_BASE_URL == API_PROD_URL) {
      FINAL_BASE_URL = COKE_BANGLA_PROD_URL;
    } else if (APP_BASE_URL == REACT_LOCAL_BASE_URL) {
      //for testing
      FINAL_BASE_URL = REACT_LOCAL_BASE_URL;
    }
    console.log(FINAL_BASE_URL, "FINAL_BASE_URL");
    // Add userId to the URL if it exists
    const urlWithUserId = userId
      ? `${FINAL_BASE_URL}?link=${userId}`
      : FINAL_BASE_URL;

    // Remove the trailing slash (if it exists)
    const cleanedUrlWithUserId = urlWithUserId.replace(/\/$/, "");
    setCokeStudioUrl(cleanedUrlWithUserId);
    console.log(FINAL_BASE_URL, "FINAL_BASE_URL");
    console.log(urlWithUserId, "urlWithUserId");
    console.log(cleanedUrlWithUserId, "cleanedUrlWithUserId");
  }, [APP_BASE_URL, userId]);

  console.log(cokeStudioUrl, "cokeStudioUrl");

  // Show my wallet code model
  const handleMyWinModel = () => {
    if (userDetails) {
      setShowMyWallet(true);
      navigateTo();
    } else {
      setShowLoginModal();
      navigateTo();
    }
  };

  return (
    <div className="tu-sidebar-list-main">
      <ul className="ml-md-7 ml-3 mb-4 list-bottom-border">
        {/* Home */}
        <li>
          <span className="tu-side-icon">
            <img src={"assets/images/hamburger/icon01.svg"} alt="home icon" />
          </span>
          <Link to="/" onClick={() => navigateTo()}>
            {t.home}
          </Link>
        </li>

        {/* My Wins */}
        <li>
          <span className="tu-side-icon">
            <img src={"assets/images/hamburger/icon02.svg"} alt="win icon" />
          </span>
          <Link to="#" onClick={handleMyWinModel}>
            {t.my_wins}
          </Link>
        </li>

        {/* Power Players */}
        <li>
          <span className="tu-side-icon">
            <img
              src={"assets/images/hamburger/icon03.svg"}
              alt="Power Players"
            />
          </span>
          <Link to="/leaderboard" onClick={() => navigateTo()}>
            Concert Ticket Winners
          </Link>
        </li>
      </ul>
      <ul className="ml-md-7 ml-3 mb-4 list-bottom-border">
        {/* Coke Studio Bangla */}
        <li>
          <Link to={cokeStudioUrl} onClick={() => navigateTo()}>
            Coke Studio Bangla
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Feature;
