// Import the `configureStore` function from `@reduxjs/toolkit`
import { configureStore } from "@reduxjs/toolkit";

// Import the `storage` object from `redux-persist/lib/storage`
import storage from "redux-persist/lib/storage";

// Import the necessary functions and constants from `redux-persist`
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// Import the root reducer from "./slice"
import rootReducers from "./slice";

// Configure the persistence for the root reducer
const persistConfig = {
  key: "root", // Key for the persisted state in the storage
  version: 1, // Version of the persisted state (used for migrations)
  storage, // Storage engine to be used (in this case, `storage` imported from `redux-persist/lib/storage`)
};

// Create a persisted reducer using the root reducer and persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducers);

// Configure the Redux store using `configureStore`
export const store = configureStore({
  reducer: persistedReducer, // Set the persisted reducer as the root reducer of the store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore specific actions during serialization check
      },
    }),
});

// Create the persistor using `persistStore` and the configured store
export const persistor = persistStore(store);
