/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../utils/hooks/use-auth";
import useTranslation from "../../utils/hooks/use-translation";
import LanguageSwitcher from "./LanguageSwicher";
import { privacy_policy, terms_condition } from "../../config/constant";

function PrivacyPolicy({ navigateTo, handleLanguageChange, currentLanguage }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { userDetails } = useAuth();
  // toggle the contact information
  const [showContactInfo, setShowContactInfo] = useState(false);

  const toggleContactInfo = () => {
    setShowContactInfo(!showContactInfo);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.dataLayer.push({ event: "bdlogoutbtn" });
    if (location.search == "?qr=true") {
      window.location.assign("?qr=true");
    } else if(location.search == "?Ref=foodpanda"){
      window.location.assign('?Ref=foodpanda');
    }else {
      window.location.assign("/");
    }
  };

  return (
    <>
      {/* Privacy Policy and Related Links */}
      <div className="tu-sidebar-privacy-main tu-privacy mt-3 pl-md-7 pl-3">
        <div className="tu-sidebar-list-main">
          <ul className="pl-0">
            {/* Privacy Policy Link */}
            <li>
              <Link
                to={privacy_policy}
                target={"_blank"}
                onClick={() => navigateTo()}
              >
                {t.privacy_policy}
              </Link>
            </li>

            {/* Terms and Conditions Link */}
            <li>
              <Link
                to={terms_condition}
                target={"_blank"}
                onClick={() => navigateTo()}
              >
                {t.terms_condition}
              </Link>
            </li>
            {/* Contact Us Link */}
            <li className={userDetails?.Id ? "list-bottom-border"  : "" }>
              <Link to="#" onClick={toggleContactInfo}>
                {t.contact_us}
              </Link>
              {showContactInfo && (
                <ul className="mt-2 pl-0 mr-5">
                  <li>{t.campaign_query_hotline} - 09610002653</li>
                </ul>
              )}
            </li>
            {userDetails && userDetails?.Id && (
              <div className="logout-btn mx-0 w-100">
                <button
                  onClick={handleLogout}
                  className={`btn btn-sm btn-light mt-3 mb-3 d-flex  bdlogoutbtn`}
                >
                  {t.logout}
                </button>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
