import React, { useState, useEffect } from 'react';
import '../styles/sass/components/grabSection.scss';
import '../styles/sass/components/steps.scss';
import CommonModel from '../components/model/CommonModel';
import HomeCarousel from './common/HomeCarousel';
import ThumbSlider from './common/ThumbSlider';
import useTranslation from '../utils/hooks/use-translation';
import useAuth from '../utils/hooks/use-auth';
import { HomeCDP } from '../utils/cdsHelper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDetailsById } from '../service';
import { setUserDetails } from '../utils/helper';
import { loginSuccess, setUser } from '../redux/slice/authenticationSlice';

// Home component
const Home = (props) => {
  // Custom hooks
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  // State variables
  const [activeStep, setActiveStep] = useState(1);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const linkParam = searchParams.get('link');
  console.log(linkParam, 'linkParam');

  // Event handler for showing the login modal
  const handleIsUserLogin = async () => {
    if (linkParam) {
      try {
        const response = await getUserDetailsById({
          Id: linkParam,
        });
        if (response && response.status == 200) {
          const userDetails = response?.data?.Data;
          setUserDetails(userDetails);
          dispatch(setUser(userDetails));
          dispatch(loginSuccess(userDetails));
          navigate('/');
        }
      } catch (err) {
        console.log(err, 'errr');
      }
    }
  };

  useEffect(() => {
    handleIsUserLogin();
  }, [linkParam]);

  const handleShowLoginModal = () => {
    setActiveStep(2);
    setShowLoginModal(true);
  };
  useEffect(() => {
    HomeCDP();
  }, []);

  // Event handler for closing the login modal
  const handleClose = () => setShowLoginModal(false);
  return (
    <>
      <main role="main" class="main">
        {/* Render HomeCarousel component with showLoginModal prop */}
        <HomeCarousel showLoginModal={showLoginModal} />
        <div className="container-fluid my-5 py-0 my-md-14 py-md-3">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-6">
                  <ThumbSlider />
                </div>
                <div className="col-12 col-md-6 text-center text-md-left">
                  <div className="grab-section">
                    <h4 className="title">
                      Grab 5 Coke bottles & unlock your entry to the Coke Studio
                      Bangla Live Concert!
                    </h4>
                    <p className="sub-title">
                      *Limited tickets available – don't miss out!"
                    </p>
                    <img
                      className="img"
                      src={'assets/images/tickets/concert.png'}
                      alt="ticket"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Steps */}

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 pb-4 pb-md-6">
              <div className="steps-wrapper mb-md-14">
                <h2 className="text-uppercase text-center mb-5 mb-md-6">
                  How To Win
                </h2>
                <div className="row row-cols-2 row-cols-md-4">
                  <div className="col steps-item mb-6">
                    <img
                      className="steps-img"
                      src={'assets/images/steps/step-1.png'}
                      alt={'Coco cola'}
                    />
                    <h5 className="steps-title">Step 1</h5>
                    <h6 className="steps-discription">
                      Find the unique code behind the label​
                    </h6>
                  </div>
                  <div className="col steps-item mb-6">
                    <img
                      className="steps-img"
                      src={'assets/images/steps/step-2.png'}
                      alt={'Coco cola'}
                    />
                    <h5 className="steps-title">Step 2</h5>
                    <h6 className="steps-discription">Enter the unique code</h6>
                  </div>
                  <div className="col steps-item">
                    <img
                      className="steps-img"
                      src={'assets/images/steps/step-3.png'}
                      alt={'Coco cola'}
                    />
                    <h5 className="steps-title">Step 3</h5>
                    <h6 className="steps-discription">Win a Silver Coin</h6>
                  </div>
                  <div className="col steps-item">
                    <img
                      className="steps-img"
                      src={'assets/images/steps/step-4.png'}
                      alt={'Coco cola'}
                    />
                    <h5 className="steps-title">Step 4</h5>
                    <h6 className="steps-discription">
                      Collect 5 Silver Coins and stand a chance to win a CSB
                      Live 2.0 ticket
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ position: 'relative' }}>
          {/* Render CommonModel component only if userDetails is falsy and showLoginModal is true */}
          {!userDetails && showLoginModal && (
            <CommonModel
              title={t.worldCup2023TicketKey}
              header={t.find_code}
              handleShowLoginModal={handleShowLoginModal}
              showLoginModal={showLoginModal}
              handleClose={handleClose}
              active={activeStep}
            />
          )}
        </div>
      </main>
    </>
  );
};

export default Home;
