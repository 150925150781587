import React from "react";
import HeroBannerContest from "./common/HeroBannerContest";
import PickupPointRules from "./common/PickupPointRules";
import ClaimCard from "./common/ClaimCard";
import MarqueeStrip from "./common/MarqueeStrip";
import useTranslation from "../utils/hooks/use-translation";

export default function HowToClaim() {
  const {t} =useTranslation()
  return (
    <>
      {/* Render the HeroBannerContest component */}
      <HeroBannerContest  image="/assets/images/hero-banner/bottle-trophy.png" alt="Banner image"
       heading={t.savour} paragraph={t.claim_reward}/>

      {/* Render the WinTicketStrip component */}
      <MarqueeStrip />

      {/* Container with background pattern */}
      <div className="bg-pattern-6">
        {/* Render the ClaimCard component */}
        <ClaimCard />

        {/* Render the PickupPointRules component */}
        <PickupPointRules />
      </div>
    </>
  );
}
